import axios from "axios";
import { API } from "./apiUrl";

const API_URL = API + "auth/";

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "user");
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod");
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin");
};

const getAllowed = (user, location) => {
  return axios
  .post(API_URL + "getAllowed.php", {
    user,
    location,
  })
  .then((response) => {
      if (response.data) {
        return response.data;
      }
  });
};

const UserService = {
  getAllowed,
}

export default UserService;