import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API } from '../../../services/apiUrl';
import useCursor from '../../utility/useCursor';
import { Modal } from '../../utility/modal';
import { ShiftPlanCalender } from './shiftPlanCalender';

const ShirtivalShiftPlan = ( {currentUser} ) => {
    
    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(false);
    const [message, setMessage] = useState("");

    const [changedStates, setChangedStates] = useState(false);

    const [machine, setMachine] = useState('');
    const [availableMachines, setAvailableMachines] = useState({});

    const [includeWeekend, setIncludeWeekend] = useState(false);

    const [initialShiftPlan, setInitialShiftPlan] = useState({});
    const [shiftPlan, setShiftPlan] = useState({});

    const resetChanges = () => {
        setLoading(true);
        setShiftPlan(initialShiftPlan);
        setChangedStates(false);
        setLoading(false);
    };

    const saveChanges = () => {
        setLoading(true);
        axios.post(`${API}shirtival/productionPlaningShiftPlan.php`, {
            methode: 'UPDATE',
            data: shiftPlan,
            machineId: machine,
            currentUser
        })
        .then(response => {
            if (response.data != '') {
                setInitialShiftPlan(JSON.parse(JSON.stringify(response.data)));
                setShiftPlan(response.data);
                setMessage('Suc-Der Schichtplan wurde erfolgreich angepasst.');
                setChangedStates(false);
            }
            setLoading(false);
        })
        .catch(error => {
            const resMessage = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
            const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
            const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
            setMessage(errorMessage);
            setLoading(false);
        });
    };

    const handleWeekend = () => {
        setIncludeWeekend(prev => !prev);
    };    

    const handleMachineChange = (event) => {
        setMachine(event.target.value); // Set the machine to the selected option value
    };

    useEffect(() => {
        setLoadingInitial(true);
        axios.post(`${API}shirtival/productionPlaningMachineEdit.php`, { methode: 'GET' })
            .then(response => {
                const sortedData = response.data.sort((a, b) => a.machine_id - b.machine_id);
                setAvailableMachines(sortedData);
                setLoadingInitial(false);
            })
            .catch(error => {
                const resMessage = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
                const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
                const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
                setMessage(errorMessage);
                setLoadingInitial(false);
            });
    }, []);

    useEffect(() => {
        setLoading(true);
        axios.post(`${API}shirtival/productionPlaningShiftPlan.php`, { methode: 'GET' , machineId: machine})
            .then(response => {
                if (response.data != '') {
                    setInitialShiftPlan(JSON.parse(JSON.stringify(response.data)));
                    setShiftPlan(response.data);
                } 
                setLoading(false); 
            })
            .catch(error => {
                const resMessage = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
                const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
                const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
                setMessage(errorMessage);
                setMachine('');
                setLoading(false);
            });
    }, [machine]);

    return (
        <React.Fragment>
        {message && (
            <Modal 
                message={message}
                setMessage={setMessage}
            />
        )}
        
        {loadingInitial ? (
            <div className="w3-section">Loading...</div>
        ) : ( 
            <React.Fragment>
            <div className="w3-section w3-mobile w3-bar">
                <select 
                    className='w3-third w3-input'
                    onChange={handleMachineChange}
                >
                {/* Render Options with Nachines */}
                {Array.isArray(availableMachines) && availableMachines.length > 0 ? (
                    <React.Fragment>
                        <option value="">Wähle eine Maschine aus</option>
                        {availableMachines.map((machine) => (
                            <option key={machine.machine_id} value={machine.machine_id}>
                                {machine.name}
                            </option>
                        ))}
                    </React.Fragment>
                ) : (
                    ''
                )}
                </select>
                {machine !== '' ? (
                <React.Fragment>   
                    <button 
                        className='w3-button w3-theme w3-bar-item' 
                        onClick={handleWeekend}
                        style={{
                        marginLeft: '8px'
                    }}
                    >
                        <i className={`fa ${includeWeekend ? 'fa-eye-slash' : 'fa-eye'} w3-margin-right`}></i> Wochenende
                    </button>
                </React.Fragment> 
                ) : ( 
                    ''
                ) }
                {changedStates && (
                <React.Fragment>
                <button 
                    className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`} 
                    style={{
                        pointerEvents: loading ? 'none' : 'auto'
                    }}
                    onClick={saveChanges}
                >
                    {!loading ? (
                        <>
                            <i className='fa fa-save w3-margin-right w3-small'></i>
                        </>
                    ) : (
                        <>
                            <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                        </>
                    )}
                    Speichern
                </button>
                <button 
                    className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`} 
                    style={{
                        pointerEvents: loading ? 'none' : 'auto',
                        marginRight: '8px'
                    }}
                    onClick={resetChanges}
                >
                    {!loading ? (
                        <>
                            <i className='fa fa-save w3-margin-right w3-small'></i>
                        </>
                    ) : (
                        <>
                            <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                        </>
                    )}
                    Zurücksetzen
                </button>
                </React.Fragment>
                )}     
            </div>
            {machine != '' ? (
                <ShiftPlanCalender includeWeekend={includeWeekend} shiftPlan={shiftPlan} setShiftPlan={setShiftPlan} setChangedStates={setChangedStates} machineId={machine} />   
            ) : (
                <React.Fragment>
                </React.Fragment>
            )}
            </React.Fragment>     
        )} 
        </React.Fragment>
    );
};

export { ShirtivalShiftPlan };


