import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API } from '../../../services/apiUrl';
import useCursor from '../../utility/useCursor';
import { Modal } from '../../utility/modal';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const Gemeinkosten = ({ currentUser, frame = 'gemeinkosten' }) => {
    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(false);
    const [tables, setTables] = useState({ [frame]: [] });
    const [initialTables, setInitialTables] = useState({ [frame]: [] });
    const [editableRows, setEditableRows] = useState([]);
    const [changedStates, setChangedStates] = useState({});
    const [durchschnitt, setDurchschnitt] = useState([]);
    const [loadingCrosshair, setLoadingCrosshair] = useState(false);
    const [message, setMessage] = useState("");
    const [errorRows, setErrorRows] = useState([]);

    const [graphData, setGraphData] = useState([]);
    const [graphMinValueData, setGraphMinValueData] = useState(0);
    const [graphMaxValueData, setGraphMaxValueData] = useState(100);

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage] = useState(10);

    // Paginate rows
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = (tables[frame] || []).slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil((tables[frame]?.length || 0) / rowsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const tooltipFormatter = (value, name, props) => {
        return [`Gemeinkosten: ${value.toFixed(2)} %`];
    };

    useEffect(() => {
        setLoadingInitial(true);
        axios.post(`${API}mkg/gemeinkosten.php`, { methode: 'GET' })
            .then(response => {
                const sortedData = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                const formattedDataDate = sortedData.map(item => {
                    const date = new Date(item.created_at);
                    const formattedDate = date.toISOString().split('T')[0];
                    const percent = parseFloat(((item.value - 1) * 100).toFixed(2));
                    return {
                        ...item,
                        created_at: formattedDate,
                        value: percent
                    };
                });
                const formattedData = formatData(formattedDataDate);
                setTables(prevTables => ({
                    ...prevTables,
                    ...formattedData,
                }));
                setInitialTables(JSON.parse(JSON.stringify(formattedData)));
                setLoadingInitial(false);
            })
            .catch(error => {
                const resMessage = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
                const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
                const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
                setMessage(errorMessage);
                setLoadingInitial(false);
            });
    }, []);

    const formatData = (data) => {
        const formattedData = {};
        data.forEach(item => {
            if (!formattedData[frame]) {
                formattedData[frame] = [];
            }
            formattedData[frame].push(item);
        });
        return formattedData;
    };

    useCursor(loadingCrosshair);

    useEffect(() => {
        setTables(prevTables => ({
            ...prevTables,
            [frame]: prevTables[frame] || [],
        }));
        setChangedStates(prevStates => ({
            ...prevStates,
            [frame]: prevStates[frame] || false
        }));
        setDurchschnitt(prevOptions => ({
            ...prevOptions,
            [frame]: prevOptions[frame] || 0,
        }));
    }, [frame]);

    const handleAddRow = () => {
        const newRow = {
            id: '',
            created_at: new Date().toISOString().split('T')[0],
            value: 0
        };
        setTables(prevTables => {
            const updatedRows = [newRow, ...(prevTables[frame] || [])];  // Ensure prevTables[frame] is defined
            return {
                ...prevTables,
                [frame]: updatedRows,
            };
        });
    
        setEditableRows(prevIndices => {
            const currentIndices = prevIndices[frame] || [];  // Ensure prevIndices[frame] is defined
            return {
                ...prevIndices,
                [frame]: [0, ...currentIndices.map(index => index + 1)]  // Adjust indices correctly
            };
        });
    
        setChangedStates(prevStates => ({
            ...prevStates,
            [frame]: true
        }));
    };

    const handleInputChange = (e, rowIndex, fieldName) => {
        const { value } = e.target;
        
        setTables(prevTables => {
            const updatedRows = (prevTables[frame] || []).map((row, index) =>
                index === rowIndex ? { ...row, [fieldName]: value } : row
            );
            return {
                ...prevTables,
                [frame]: updatedRows,
            };
        });

        setChangedStates(prevStates => ({
            ...prevStates,
            [frame]: true,
        }));
    };

    useEffect(() => {
        setLoadingCrosshair(true);
        const totalCosts = {};
        Object.keys(tables).forEach(type => {
            let totalCost = 0;
            tables[type].forEach(row => {
                const cost = parseFloat(row.value);
                totalCost += cost;
            });
            const averageCost = (totalCost / tables[type].length);
            totalCosts[type] = isNaN(averageCost) ? 0 : averageCost;
        });
        setDurchschnitt(totalCosts);
        setLoadingCrosshair(false);
    }, [tables]);

    const saveChanges = () => {
        setErrorRows([]);
        const rowsWithError = [];
        for (const type in tables) {
            for (const [index, item] of tables[type].entries()) {
                if (item.hasOwnProperty('id') && item['id'] === '') {
                    const valuesToCheck = Object.keys(item).filter(key => key !== 'id');
                    const hasEmptyOrZeroValue = valuesToCheck.some(key => item[key] === '' || item[key] === 0 || item[key] === "0");
                    const value = parseFloat(item.value);
                    if (hasEmptyOrZeroValue || isNaN(value) || value >= 100) {
                        const errorMessage = [];
                        if (hasEmptyOrZeroValue) {
                            errorMessage.push("Fülle bitte alle Felder aus.");
                        }
                        if (isNaN(value)) {
                            errorMessage.push("Prozentwert muss eine Zahl sein.");
                        }
                        if (value >= 100) {
                            errorMessage.push("Prozentwert kann nicht 100 sein.");
                        }
                        rowsWithError.push({ errorMessage, index });
                    }
                }
            }
        }
        if (rowsWithError.length > 0) {
            setErrorRows(prevState => [...prevState, ...rowsWithError]);
            return;
        }
        setLoading(true);
        setLoadingCrosshair(true);
        const modifiedTables = {};  // Initialize an empty object

        modifiedTables[frame] = tables[frame].map(item => {
            const now = new Date();
            const formattedDateTime = now.toISOString().slice(0, 19).replace('T', ' ');
        
            const createdAt = item.created_at 
                ? item.created_at + ' ' + now.toTimeString().split(' ')[0]
                : formattedDateTime;
        
            const value = item.value / 100 + 1;
        
            return {
                ...item,
                created_at: createdAt,
                value: value
            };
        });
        axios.post(`${API}mkg/gemeinkosten.php`, {
            methode: 'UPDATE',
            data: modifiedTables,
            currentUser
        })
        .then(response => {
            const sortedData = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            const formattedDataDate = sortedData.map(item => {
                const date = new Date(item.created_at);
                const formattedDate = date.toISOString().split('T')[0];
                const percent = parseFloat(((item.value - 1) * 100).toFixed(2));
                return {
                    ...item,
                    created_at: formattedDate,
                    value: percent
                };
            });
            setTables(formatData(formattedDataDate));
            setChangedStates({});
            setErrorRows([]);
            setEditableRows([]);
            setMessage('Suc-Die Gemeinkosten wurden erfolgreich angepasst.');
            setLoading(false);
            setLoadingCrosshair(false);
        })
        .catch(error => {
            const resMessage = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
            const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
            const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
            setMessage(errorMessage);
            setLoading(false);
            setLoadingCrosshair(false);
        });
    };

    const resetChanges = () => {
        setLoading(true);
        setTables(initialTables);
        setChangedStates({});
        setErrorRows([]);
        setEditableRows([]);
        setCurrentPage(1);
        setLoading(false);
    };

    useEffect(() => {
        const areTablesEqual = JSON.stringify(tables) === JSON.stringify(initialTables);
        if (areTablesEqual) {
            setChangedStates({});
            setErrorRows([]);
            setEditableRows([]);
        }
    }, [tables]);

    useEffect(() => {
        // Create a deep copy of the tables object
        const tablesCopy = JSON.parse(JSON.stringify(tables));
        // Step 1: Sort the data by date
        const sortedData = tablesCopy[frame].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        // Step 2: Format the dates
        const formattedData = sortedData.map(item => ({
            ...item,
            created_at: formatDate(item.created_at),
            value: parseFloat(item.value)  // Ensure the value is treated as a number
        }));
        setGraphMinValueData(Math.min(...formattedData.map(item => item.value)));
        setGraphMaxValueData(Math.max(...formattedData.map(item => item.value)));
        setGraphData(formattedData);
    }, [tables]);

    function formatDate(dateString) {
        const [year, month, day] = dateString.split("-");
        return `${day}.${month}.${year}`;
    }

    const removeRowFromErrors = (rowToRemove) => {
        setErrorRows(prevErrorRows => prevErrorRows.filter(errorRow => errorRow.index !== tables[frame].indexOf(rowToRemove)));
    };

    return (
        <>
            {message && (
                <Modal message={message} setMessage={setMessage} />
            )}
            {loadingInitial ? (
                <div className="w3-section w3-display-container" style={{ height: '200px' }}>
                    <div className="w3-display-middle">
                        <i className='fa fa-spinner fa-spin w3-margin-right w3-large'></i>
                    </div>
                </div>
            ) : (
                <>
                    <div className="w3-section w3-mobile w3-bar">
                        <button className='w3-button w3-theme w3-bar-item' onClick={handleAddRow}>
                            <i className='fa fa-plus w3-margin-right w3-small'></i> Neuer Prozentwert
                        </button>
                        {changedStates[frame] && (
                            <React.Fragment>
                                <button
                                    className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`}
                                    style={{ pointerEvents: loading ? 'none' : 'auto' }}
                                    onClick={saveChanges}
                                >
                                    {!loading ? (
                                        <>
                                            <i className='fa fa-save w3-margin-right w3-small'></i>
                                        </>
                                    ) : (
                                        <>
                                            <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                                        </>
                                    )}
                                    Speichern
                                </button>
                                <button
                                    className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right`}
                                    style={{ pointerEvents: loading ? 'none' : 'auto', marginRight: '8px' }}
                                    onClick={resetChanges}
                                >
                                    {!loading ? (
                                        <>
                                            <i className='fa fa-save w3-margin-right w3-small'></i>
                                        </>
                                    ) : (
                                        <>
                                            <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                                        </>
                                    )}
                                    Zurücksetzen
                                </button>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="w3-section w3-mobile">
                        <table className="w3-table-all w3-bordered w3-border" style={{ tableLayout: 'auto' }}>
                            <thead>
                                <tr key={'header'.frame} className='w3-light-grey'>
                                    <th style={{ width: 'auto' }}>Datum</th>
                                    <th style={{ width: 'auto' }}>Prozentwert</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentRows.map((row, index) => (
                                    <React.Fragment key={`${frame}-${index}`}>
                                        <tr key={index} className='w3-white'>
                                            <td style={{ padding: '0 0' }}>
                                                <input
                                                    className='w3-input w3-border-0 w3-white'
                                                    style={{ textAlign: 'right' }}
                                                    type="date"
                                                    value={row.created_at}
                                                    onChange={(e) => handleInputChange(e, index, 'created_at')}
                                                    disabled={!editableRows[frame]?.includes(index)}
                                                />
                                            </td>
                                            <td style={{ padding: '0 0' }}>
                                                <input
                                                    className='w3-input w3-border-0 w3-white'
                                                    style={{ textAlign: 'right' }}
                                                    type="number"
                                                    step={0.1}
                                                    min={0}
                                                    value={row.value}
                                                    onChange={(e) => handleInputChange(e, index, 'value')}
                                                    disabled={!editableRows[frame]?.includes(index)}
                                                />
                                            </td>
                                        </tr>
                                        {errorRows.some(errorRow => errorRow.index === index) &&
                                            <tr key={`error-${index}`}>
                                                <td colSpan={2} className="w3-display-container w3-red">
                                                    <div>
                                                        <span
                                                            onClick={() => removeRowFromErrors(row)}
                                                            className="w3-button w3-display-topright">
                                                            X
                                                        </span>
                                                        {errorRows
                                                            .filter(errorRow => errorRow.index === index)
                                                            .map((errorRow, errorIndex) => (
                                                                <div key={`error-${index}-${errorIndex}`}>
                                                                    {errorRow.errorMessage.map((message, messageIndex) => (
                                                                        <p key={`errorMessage-${index}-${errorIndex}-${messageIndex}`}>{message}</p>
                                                                    ))}
                                                                </div>
                                                        ))}
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </React.Fragment>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr className='w3-light-grey'>
                                    <td colSpan={0} className='w3-right-align'>
                                        Durchschnittswert
                                    </td>
                                    <td colSpan={0} className='w3-right-align' style={{ padding: '8px 11px' }}>
                                        {durchschnitt[frame] !== null && durchschnitt[frame] !== undefined && !isNaN(durchschnitt[frame])
                                            ? durchschnitt[frame].toLocaleString('de-DE', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })
                                            : '0'} %
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className="w3-section w3-mobile">
                        <div className="w3-bar">
                            <button
                                className={`w3-button ${currentPage === 1 ? 'w3-disabled' : 'w3-theme'}`}
                                onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                <i className='fa fa-chevron-left'></i>
                            </button>
                            {[...Array(totalPages).keys()].map(pageNumber => (
                                <button
                                    key={pageNumber}
                                    className={`w3-button ${currentPage === pageNumber + 1 ? 'w3-theme' : ''}`}
                                    onClick={() => handlePageChange(pageNumber + 1)}
                                >
                                    {pageNumber + 1}
                                </button>
                            ))}
                            <button
                                className={`w3-button ${currentPage === totalPages ? 'w3-disabled' : 'w3-theme'}`}
                                onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                <i className='fa fa-chevron-right'></i>
                            </button>
                        </div>
                    </div>
                    <div className="w3-section w3-mobile">
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart
                                data={graphData}
                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="created_at" />
                                <YAxis
                                    domain={[graphMinValueData, graphMaxValueData]}
                                    interval="preserveStartEnd"
                                    allowDecimals={true}
                                />
                                <Tooltip formatter={tooltipFormatter} />
                                <Legend
                                    payload={[
                                        {
                                            value: 'Gemeinkosten (%)',
                                            type: 'line',
                                            color: '#b42733',
                                        },
                                    ]}
                                />
                                <Line type="monotone" dataKey="value" stroke="#b42733" />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </>
            )}
        </>
    );
};

export { Gemeinkosten };
