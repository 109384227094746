import React, { useState } from "react";
import { TabButton } from '../utility/tabButton.jsx';
import { UserCreate } from './user/create.jsx';
import { UserDelete } from "./user/delete.jsx";
import { UserUpdate } from "./user/update.jsx";

const AdminUser = ({ currentUser }) => {

    const [activeComponent, setActiveComponent] = useState('create');

    const renderComponent = (component) => {
        switch (component) {
            case 'create':
                return <UserCreate currentUser={currentUser} />;
            case 'update':
                return <UserUpdate currentUser={currentUser} />;
            case 'delete':
                return <UserDelete currentUser={currentUser} />;
            default:
                return null;
        }
    };

    return (
        <div className="w3-container">
            <h2>Benutzerverwaltung</h2>
            <div className="w3-section">
                <div className="w3-bar w3-theme">
                    <TabButton onClick={() => setActiveComponent('create')} isActive={activeComponent === 'create'}>Erstellen</TabButton>
                    <TabButton onClick={() => setActiveComponent('update')} isActive={activeComponent === 'update'}>Verändern</TabButton>
                    <TabButton onClick={() => setActiveComponent('delete')} isActive={activeComponent === 'delete'}>Löschen</TabButton>
                </div>
            </div>
            {renderComponent(activeComponent)}
        </div>
    );
};

export { AdminUser };