import React, { useState } from 'react';
import { Modal } from './utility/modal';
import { Logo } from './utility/Logo';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { API } from '../services/apiUrl';

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleTokenChange = (e) => {
    setToken(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    // Reset error messages
    setError('');
    setMessage('');
  
    // Initialize an object to hold error messages
    const errors = {};

    // Validate token
    if (token.trim() === '') {
      errors.token = 'Es muss ein Token angegeben werden.';
    }

    // Validate password
    if (password.trim() === '') {
      errors.password = 'Das Passwort Feld darf nicht leer sein.';
    }

    // Validate confirmPassword
    if (confirmPassword.trim() === '') {
      errors.confirmPasswordEmpty = 'Das Passwort bestätigen Feld darf nicht leer sein.';
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      errors.confirmPassword = 'Die Passwörter stimmen nicht überein.';
    }

    // Check if there are any errors
    if (Object.keys(errors).length > 0) {
      setError(errors);
      setLoading(false);
      return;
    }
  
    try {
      axios.post(`${API}auth/reset_password.php`, {
        token: token,
        password: password
      })
      .then(response => {
        setMessage('Suc-'+response.data);
        setPassword('');
        setConfirmPassword('');
        setToken('');
        setTimeout(() => {
          navigate('/');
        }, 4000);
      })
      .catch(error => {
        console.log(error);
          const resMessage =
              (error.response &&
              error.response.data &&
              error.response.data.detail) ||
              error.message ||
              error.toString();
          const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
          const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
          setMessage(errorMessage);
      })
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w3-display-container" style={{ height: '100vh', width: '100vw' }}>

        {message && (
        <Modal message={message} setMessage={setMessage}/>
        )}

        <div className="w3-container w3-display-middle w3-mobile" style={{ width: '30rem' }}>
            <div className="w3-card-4 w3-light-grey w3-padding">
                <div className="w3-center">
                    <Logo src='/SGB-logo.png' alt="Logo" width="50%" className="w3-margin-top"/>
                </div>
                
                <form onSubmit={handleSubmit} className="w3-container">
                <div className="w3-section">
                    <label>Token</label>
                    <input
                    type="text"
                    className="w3-input w3-margin-bottom w3-light-grey"
                    name="token"
                    value={token}
                    onChange={handleTokenChange}
                    />
                    {error.token && <div className='w3-red w3-margin-bottom'>{error.token}</div>}

                    <label>Neues Password</label>
                    <input
                    type="password"
                    className="w3-input w3-margin-bottom w3-light-grey"
                    name="password"
                    value={password}
                    onChange={handlePasswordChange}
                    />
                    {error.password && <div className='w3-red w3-margin-bottom'>{error.password}</div>}

                    <label>Password bestätigen</label>
                    <input
                    type="password"
                    className="w3-input w3-margin-bottom w3-light-grey"
                    name="confirmpassword"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    />
                    {error.confirmPasswordEmpty && <div className='w3-red w3-margin-bottom'>{error.confirmPasswordEmpty}</div>}
                    {error.confirmPassword && <div className='w3-red w3-margin-bottom'>{error.confirmPassword}</div>}

                    <div className="w3-bar w3-section">
                        <button className="w3-button w3-bar-item w3-theme w3-padding" disabled={loading} type='submit'>
                            {!loading && (
                                <i className="fa fa-sign-in fa-fw w3-margin-right"></i>
                            )}
                            {loading && (
                                <i className="fa fa-spinner fa-spin fa-fw w3-margin-right"></i>
                            )}
                            <span>Login</span>
                        </button>
                    </div>
                </div>
                </form>
            </div>
        </div>
    </div>
  );
};

export default ResetPassword;