import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API } from '../../../services/apiUrl';
import useCursor from '../../utility/useCursor';
import { Modal } from '../../utility/modal';
import { Calculation } from '../components/calculation';

const GalvanizingPrice = ({currentUser, frame = 'galvanizing'}) => {

    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(false);
    const [tables, setTables] = useState({ [frame]: [] });
    const [initialTables, setInitialTables] = useState({ [frame]: [] });
    const [editableRows, setEditableRows] = useState([]);
    const [changedStates, setChangedStates] = useState({});
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [durchschnitt, setDurchschnitt] = useState([]);
    const [bezugsquelle, setBezugsquelle] = useState([]);
    const [loadingCrosshair, setLoadingCrosshair] = useState(false);
    const [message, setMessage] = useState("");
    const [errorRows, setErrorRows] = useState([]);

    useEffect(() => {
        setLoadingInitial(true);
        axios.post(`${API}mkg/galvanizingPriceList.php`, {
            methode: 'GET'
        })
        .then(response => {
            const formattedData = formatData(response.data);
            setTables(prevTables => ({
                ...prevTables,
                ...formattedData,
            }));
            setInitialTables(JSON.parse(JSON.stringify(formattedData)));
            setLoadingInitial(false)
        })
        .catch(error => {
            const resMessage =
                (error.response &&
                error.response.data &&
                error.response.data.detail) ||
                error.message ||
                error.toString();
            const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
            const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
            setMessage(errorMessage);
            setLoadingInitial(false)
        })
    }, []);

    const formatData = (data) => {
        const formattedData = {};
        data.forEach(item => {
            if (!formattedData[frame]) {
                formattedData[frame] = [];
            }
            formattedData[frame].push(item);
        });
        return formattedData;
    };

    useCursor(loadingCrosshair);

    useEffect(() => {      
        setTables(prevTables => ({
            ...prevTables,
            [frame]: prevTables[frame] || [],
        })); 
        setChangedStates(prevStates => ({
          ...prevStates,
          [frame]: prevStates[frame] || false
        }));
        setSupplierOptions(prevOptions => ({
            ...prevOptions,
            [frame]: prevOptions[frame] || [],
        }));
        setDurchschnitt(prevOptions => ({
            ...prevOptions,
            [frame]: prevOptions[frame] || 0,
        }));
        setBezugsquelle({
            [frame]: {
                date: bezugsquelle[frame]?.date || '',
                value: bezugsquelle[frame]?.value || 0
            }
        });
    }, [frame]);

    const handleAddRow = () => {
        const newRow = {
            id: '',
            supplierId: '',
            supplier: '',
            type: 'C80',
            lengthfrom: 0,
            lengthto: 0,
            price: 0,
        };
        setTables(prevTables => ({
            ...prevTables,
            [frame]: [...prevTables[frame], newRow],
        }));
        setEditableRows(prevIndices => ({
            ...prevIndices,
            [frame]: [...(prevIndices[frame] || []), tables[frame].length]
        }));
        setChangedStates(prevStates => ({
            ...prevStates,
            [frame]: true
        }));
        setSupplierOptions(prevOptions => ({
            ...prevOptions,
            [frame]: [...prevOptions[frame], []],
        }));
    };

    const handleRemoveRow = (index) => {
        setTables(prevTables => {
            const updatedTables = { ...prevTables };
            updatedTables[frame] = prevTables[frame].filter((_, i) => i !== index);
            return updatedTables;
        });
        setChangedStates(prevStates => ({
            ...prevStates,
            [frame]: true
        }));
    };

    const handleInputChange = (e, rowIndex, fieldName) => {
        const { value, checked } = e.target;

        if (fieldName == 'supplier') {
            axios.post(`${API}mkg/blanketPurchaseOrderOptions.php`, {
                currentUser,
                supplier: value
            })
            .then(response => {
                setSupplierOptions(prevOptions => {
                    const newOptions = { ...prevOptions };
                    newOptions[frame][rowIndex] = response.data;
                    return newOptions;
                })
            })
            .catch(error => {
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.detail) ||
                    error.message ||
                    error.toString();
                const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
                const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
                setMessage(errorMessage);
            })
            setTables(prevTables => {
                const updatedRows = [...prevTables[frame]];
                updatedRows[rowIndex][fieldName] = value;
                const supplierOption = supplierOptions[frame][rowIndex]?.find(option => option.company === value);
                if (supplierOption) {
                    updatedRows[rowIndex]['supplierId'] = supplierOption.id;
                }
                return {
                    ...prevTables,
                    [frame]: updatedRows,
                };
            });      
        } else {
            setTables(prevTables => {
                const updatedRows = [...prevTables[frame]];
                updatedRows[rowIndex][fieldName] = value;
                return {
                    ...prevTables,
                    [frame]: updatedRows,
                };
            });
        }
        setChangedStates(prevStates => ({
            ...prevStates,
            [frame]: true,
        }));

    };

    useEffect(() => {
        setLoadingCrosshair(true);
        const totalCosts = {};
        Object.keys(tables).forEach(type => {
            let totalCost = 0;
            tables[type].forEach(row => {
                const cost = parseFloat(row.price);
                totalCost += cost;
            });
            const averageCost = (totalCost / tables[type].length) / 1000;
            totalCosts[type] = isNaN(averageCost) ? 0 : averageCost;
        });
        setDurchschnitt(totalCosts);
        setLoadingCrosshair(false);
    }, [tables]);

    const saveChanges = () => {
        setErrorRows([])
        const rowsWithError = [];
        for (const type in tables) {
            for (const [index, item] of tables[type].entries()) {
                if (item.hasOwnProperty('id') && item['id'] === '') {
                    const valuesToCheck = Object.keys(item).filter(key => key !== 'id');
                    const hasEmptyOrZeroValue = valuesToCheck.some(key => item[key] === '' || item[key] === 0 || item[key] === "0");
                    const lengthFrom = parseFloat(item.lengthfrom);
                    const lengthTo = parseFloat(item.lengthto);
                    if (hasEmptyOrZeroValue || isNaN(lengthFrom) || isNaN(lengthTo) || lengthFrom >= lengthTo) {
                        const errorMessage = [];
                        if (hasEmptyOrZeroValue) {
                            errorMessage.push("Fülle bitte alle Felder aus.");
                        }
                        if (isNaN(lengthFrom) || isNaN(lengthTo)) {
                            errorMessage.push("Länge muss eine Zahl sein.");
                        }
                        if (lengthFrom >= lengthTo) {
                            errorMessage.push("Länge \"von\" muss kleiner als Länge \"bis\" sein.");
                        }
                        rowsWithError.push({ errorMessage, index });
                    }
                }
            }
        }
        if (rowsWithError.length > 0) {
            setErrorRows(prevState => [...prevState, ...rowsWithError]);
            return;
        }
        setLoading(true);
        setLoadingCrosshair(true);
        axios.post(`${API}mkg/galvanizingPriceList.php`, {
            methode: 'UPDATE',
            data: tables,
            currentUser
        })
        .then(response => {
            setTables(formatData(response.data));
            setChangedStates({});
            setErrorRows([])
            setEditableRows([])
            setMessage('Suc-Die Verzinkungspreise wurden erfolgreich angepasst.')
            setLoading(false)
            setLoadingCrosshair(false);
        })
        .catch(error => {
            const resMessage =
                (error.response &&
                error.response.data &&
                error.response.data.detail) ||
                error.message ||
                error.toString();
            const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
            const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
            setMessage(errorMessage);
            setLoading(false)
            setLoadingCrosshair(false);
        })
    };

    const handlePriceChange = () => {
        setLoading(true);
        setLoadingCrosshair(true);
        axios.post(`${API}mkg/articleSupplySource.php`, {
            price: bezugsquelle,
            currentUser
        })
        .then(response => {
            setMessage('Suc-Die Bezugsquellen der Verzinkung wurden erfolgreich angepasst.')
            setLoading(false)
            setLoadingCrosshair(false);
        })
        .catch(error => {
            const resMessage =
                (error.response &&
                error.response.data &&
                error.response.data.detail) ||
                error.message ||
                error.toString();
            const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
            const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
            setMessage(errorMessage);
            setLoading(false)
            setLoadingCrosshair(false);
        })
    }

    const handleInputChangeBezugsquelle = (e, fieldName) => {
        const { value } = e.target;
        setBezugsquelle(prevBezugsquelle => ({
            [frame]: {
                ...prevBezugsquelle[frame],
                [fieldName]: value
            }
        }));
    }

    useEffect(() => {
        setBezugsquelle(prevBezugsquelle => ({
            [frame]: {
                ...prevBezugsquelle[frame],
                value: durchschnitt[frame]
            }
        }));
    }, [durchschnitt]);

    const resetChanges = () => {
        setLoading(true)
        setTables(initialTables);
        setChangedStates({});
        setErrorRows([])
        setEditableRows([])
        setLoading(false)
    };

    useEffect(() => {
        const areTablesEqual = JSON.stringify(tables) === JSON.stringify(initialTables);
        if (areTablesEqual) {
            setChangedStates({});
            setErrorRows([]);
            setEditableRows([]);
        }
    }, [tables]);

    const removeRowFromErrors = (rowToRemove) => {
        setErrorRows(prevErrorRows => prevErrorRows.filter(errorRow => errorRow.index !== tables[frame].indexOf(rowToRemove)));
    };

    return (
        <>
        {message && (
        <Modal message={message} setMessage={setMessage}/>
        )}
        {loadingInitial ? (    
            <div className="w3-section w3-display-container" style={{height: '200px'}}>
                <div className="w3-display-middle">
                    <i className='fa fa-spinner fa-spin w3-margin-right w3-large'></i>
                </div>    
            </div> 
        ) : (
            <>
            <div className="w3-section w3-mobile w3-bar">
                <button className='w3-button w3-theme w3-bar-item' onClick={handleAddRow}>
                    <i className='fa fa-plus w3-margin-right w3-small'></i>Neuer Preis
                </button>
                {changedStates[frame] && (
                    <React.Fragment>
                    <button 
                        className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`} 
                        style={{
                            pointerEvents: loading ? 'none' : 'auto'
                        }}
                        onClick={saveChanges}
                    >
                        {!loading ? (
                            <>
                                <i className='fa fa-save w3-margin-right w3-small'></i>
                            </>
                        ) : (
                            <>
                                <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                            </>
                        )}
                        Speichern
                    </button>
                    <button 
                        className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right`} 
                        style={{
                            pointerEvents: loading ? 'none' : 'auto',
                            marginRight: '8px'
                        }}
                        onClick={resetChanges}
                    >
                        {!loading ? (
                            <>
                                <i className='fa fa-save w3-margin-right w3-small'></i>
                            </>
                        ) : (
                            <>
                                <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                            </>
                        )}
                        Zurücksetzen
                    </button>
                    </React.Fragment>
                )}     
            </div>
            <div className="w3-section w3-mobile">
                <table className="w3-table-all w3-bordered w3-border" style={{tableLayout: 'auto'}}>
                    <thead>
                        <tr key={'header'.frame} className='w3-light-grey'>
                            <th style={{width: 'auto'}}>Verzinker</th>
                            <th style={{width: 'auto'}}>Pfostentyp</th>
                            <th style={{width: 'auto'}}>Länge von (mm)</th>
                            <th style={{width: 'auto'}}>Länge bis (mm)</th>
                            <th style={{width: 'auto'}}>Preis pro Tonne</th>
                            <th style={{width: 'auto'}}>Preis pro kg</th>
                            <th style={{width: 'auto'}}></th>
                        </tr>
                    </thead>
                    <tbody>
                    {tables[frame]?.map((row, index) => (
                        <React.Fragment key={`${frame}-${index}`}>
                        <tr key={index} className='w3-white'>
                            <td style={{padding: '0 0'}}>
                                <input
                                    title={row.supplier}
                                    className='w3-input w3-border-0 w3-white'
                                    type="text"
                                    value={row.supplier}
                                    onChange={(e) => handleInputChange(e, index, 'supplier')}
                                    disabled={!editableRows[frame]?.includes(index)}
                                    list={`supplierOptions.${frame}.${index}`}
                                />
                                {editableRows[frame]?.includes(index) && (
                                    <datalist id={`supplierOptions.${frame}.${index}`}>
                                    {supplierOptions[frame][index]?.map((option, optionIndex) => (
                                        <option key={`supplierOption.${frame}.${index}.${optionIndex}`} value={option.company}>{option.supplierNumber}</option>
                                    ))}
                                </datalist>)}
                            </td>
                            <td style={{padding: '0 0'}}>
                                <select
                                    className='w3-input w3-border-0 w3-white'
                                    style={{textAlign: 'right'}}
                                    value={row.type}
                                    onChange={(e) => handleInputChange(e, index, 'type')}
                                    disabled={!editableRows[frame]?.includes(index)}
                                >
                                    <option value="C80">C80</option>
                                    <option value="C90">C90</option>
                                    <option value="C100">C100</option>
                                    <option value="C120">C120</option>
                                </select>
                            </td>
                            <td style={{padding: '0 0'}}>
                                <input
                                    className='w3-input w3-border-0 w3-white'
                                    style={{textAlign: 'right'}}
                                    type="number"
                                    step={100}
                                    min={0}
                                    value={row.lengthfrom}
                                    onChange={(e) => handleInputChange(e, index, 'lengthfrom')}
                                    disabled={!editableRows[frame]?.includes(index)}
                                />
                            </td>
                            <td style={{padding: '0 0'}}>
                                <input
                                    className='w3-input w3-border-0 w3-white'
                                    style={{textAlign: 'right'}}
                                    type="number"
                                    step={100}
                                    min={0}
                                    value={row.lengthto}
                                    onChange={(e) => handleInputChange(e, index, 'lengthto')}
                                    disabled={!editableRows[frame]?.includes(index)}
                                />
                            </td>
                            <td style={{padding: '0 0'}}>
                                <input
                                    className='w3-input w3-border-0 w3-white'
                                    style={{textAlign: 'right'}}
                                    type="number"
                                    step={.1}
                                    min={0}
                                    value={row.price}
                                    onChange={(e) => handleInputChange(e, index, 'price')}
                                    disabled={!editableRows[frame]?.includes(index)}
                                />
                            </td>
                            <td style={{padding: '0 0'}}>
                                <input
                                    className='w3-input w3-border-0 w3-white'
                                    style={{textAlign: 'right'}}
                                    type="number"
                                    value={(row.price/1000).toFixed(2)}
                                    disabled={true}
                                />
                            </td>
                            <td style={{padding: '0 0'}}>
                                <div className="w3-button w3-hover-text-theme">
                                    <i 
                                        className={`fa fa fa-trash w3-large w3-text-theme`}
                                        onClick={() => handleRemoveRow(index)}
                                    />
                                </div>
                            </td>
                        </tr>
                        {errorRows.some(errorRow => errorRow.index === index) && 
                            <tr key={`error-${index}`}>
                                <td colSpan={7} className="w3-display-container w3-red">
                                    <div>
                                        <span 
                                            onClick={() => removeRowFromErrors(row)}
                                            className="w3-button w3-display-topright">
                                            X
                                        </span>
                                        {errorRows
                                            .filter(errorRow => errorRow.index === index)
                                            .map((errorRow, errorIndex) => (
                                                <div key={`error-${index}-${errorIndex}`}>
                                                    {errorRow.errorMessage.map((message, messageIndex) => (
                                                        <p key={`errorMessage-${index}-${errorIndex}-${messageIndex}`}>{message}</p>
                                                    ))}
                                                </div>
                                        ))}
                                    </div>
                                </td>
                            </tr>
                        }
                        </React.Fragment>
                    ))}  
                    </tbody>
                    <tfoot>
                        <tr className='w3-light-grey'>
                            <td colSpan={4}></td>
                            <td className='w3-right-align'>
                                Durchschnittswert
                            </td>
                            <td colSpan={1} className='w3-right-align' style={{padding: '8px 11px'}}>
                                {durchschnitt[frame] !== null && durchschnitt[frame] !== undefined && durchschnitt[frame] !== NaN
                                ? durchschnitt[frame].toLocaleString('de-DE', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })
                                : '0'} €
                            </td>
                            <td ></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className="w3-section w3-mobile">
                <div key={frame+'.bezugsquelle'} className='w3-bar'>
                    <div className="w3-bar-item">
                        Startdatum:
                    </div>
                    <input 
                        className='w3-input w3-bar-item'
                        placeholder='Startdatum'
                        type='date'
                        value={bezugsquelle[frame]?.date}
                        onChange={(e) => handleInputChangeBezugsquelle(e, 'date')}
                    />
                    <div className="w3-bar-item">
                        Durchschnitt:
                    </div>
                    <input 
                        className='w3-input w3-bar-item'
                        placeholder='Durchschnitt'
                        type='number'
                        min={0}
                        step={0.01}
                        value={bezugsquelle[frame]?.value}
                        onChange={(e) => handleInputChangeBezugsquelle(e, 'value')}
                    />
                    <button 
                        style={{
                            pointerEvents: loading ? 'none' : 'auto'
                        }}
                        className={`w3-button w3-bar-item ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right`} 
                        onClick={handlePriceChange}>
                        {!loading ? (
                            <>
                                <i className='fa fa-plus w3-margin-right w3-small'></i>Bezugsquelle anpassen
                            </>
                        ) : (
                            <>
                                <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>Bezugsquelle anpassen
                            </>
                        )}
                    </button>
                </div>
            </div>
            <Calculation table={tables} frame={frame}/>
            </>
        )}
        </>
    );
};

export { GalvanizingPrice };