import React, { useState } from "react";

const Error = () => {
  return (
    <div className="w3-container">
      <h2>404</h2>
      <p>Diese Seite ist nicht vorhanden.</p>
    </div>
  );
};

export { Error };
