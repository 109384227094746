import React from "react";

const TabButton = ({ onClick, children, isActive, disabled }) => {
  return (
    <button
      className={`w3-bar-item w3-button  ${isActive ? "w3-black" : ""}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export { TabButton };
