import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { NavItem } from './utility/NavItem.jsx';
import { Logo } from './utility/Logo.jsx';

const Navigation = ({ isSidebarOpen, toggleSidebar, imageUrl, logOut, navItems }) => {
    
    const [activeNavItem, setActiveNavItem] = useState('');
    const location = useLocation();
  
    const handleNavItemClick = (label) => {
      setActiveNavItem(label);
    };

    return (
      <>
        <nav className={`w3-sidebar ${isSidebarOpen ? '' : 'w3-collapse'} w3-light-grey w3-animate-left w3-display-container`} style={{ zIndex: 3, width: 300 }} id="sidebar">
            <br></br>
            <div className="w3-hide-large w3-container w3-right">
                <span className="w3-padding w3-hover-grey w3-display-topright" title="close menu" onClick={toggleSidebar}>
                    <i className="fa fa-remove"></i>
                </span>
            </div>
            <div className="w3-container w3-center w3-padding-16">
                <Logo src={ imageUrl } width="214px" />
            </div>
            <div className="w3-bar-block" id="nav">
                {navItems.length === 0 ? (
                    <span className='w3-bar-item w3-button w3-padding w3-hover-text-theme w3-wide'>Fehler beim Abrufen der Navigation.</span>
                ) : (
                    <>
                        {navItems.map((item, index) => (
                            <NavItem
                                key={index}
                                item={item}
                                onClick={handleNavItemClick}
                                onSidebarToggle={toggleSidebar}
                                isActive={item.link === location.pathname}
                            />
                        ))}
                    </>
                )}
                <NavItem
                    key={99}
                    item={{label: 'LOG-OUT', iconClass: 'fa fa-sign-out'}}
                    onClick={logOut}
                    onSidebarToggle={toggleSidebar}
                />
            </div>
            <div className="w3-bar w3-bar w3-large w3-padding-24 w3-center w3-row-padding">
                <i className="fa fa-facebook-official w3-hover-text-theme w3-margin-right"></i>
                <i className="fa fa-instagram w3-hover-text-theme w3-margin-right"></i>
                <i className="fa fa-twitter w3-hover-text-theme w3-margin-right"></i>
                <i className="fa fa-linkedin w3-hover-text-theme"></i>
            </div>
        </nav>
      </>
    );
};
    
export { Navigation };