import React from 'react';

const Logo = ({ src = "", alt = "", width = "auto", height = "auto", className = ""}) => {
    return (
        <img
            src={src}
            alt={alt}
            height={height}
            width={width}
            className={className}
        />
    );
};

export { Logo };
