import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API } from '../../../services/apiUrl';
import { Modal } from '../../utility/modal';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";

const NavigationChange = () => {
    const [loading, setLoading] = useState(false);
    const [navigations, setNavigations] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [message, setMessage] = useState("");
    const [expandIndices, setExpandIndices] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState({});

    const onChangeCompanies = (e, navigationId) => {
        const options = e.target.options;
        const selectedCompanyIds = [];
    
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                selectedCompanyIds.push(options[i].value); // Push the selected value to the array
            }
        }
    
        setSelectedCompany(prevCompanies => ({
            ...prevCompanies,
            [navigationId]: selectedCompanyIds // Update the state with selected company IDs
        }));
        console.log("New selectedCompany state:", {
            ...selectedCompany,
            [navigationId]: selectedCompanyIds
        });
    };

    const updateUser = (e, userId) => {
        e.preventDefault();
    
        setMessage("");
        setLoading(true);

        // const userToUpdate = navigations.find(user => user.user_id === userId);
        // console.log(userToUpdate)

        setLoading(false);

        // axios.post(`${API}/admin/user/updateUser.php`, {
        //     userToUpdate
        // })
        // .then(response => {
        //     setLoading(false);
        //     setMessage('Suc-'+response.data);
        // })
        // .catch(error => {
        //     const resMessage =
        //         (error.response &&
        //         error.response.data &&
        //         error.response.data.error) ||
        //         error.message ||
        //         error.toString();
        //     setLoading(false);
        //     setMessage(resMessage);
        // })
    };

    const toggleExpand = (index) => {
        setExpandIndices(prevIndices => {
            if (prevIndices.includes(index)) {
                return prevIndices.filter(i => i !== index);
            } else {
                return [...prevIndices, index];
            }
        });
    };

    useEffect(() => {
        setLoading(true);

        axios.get(`${API}/admin/navigation/queryNavigation.php`)
            .then(response => {
                if (Array.isArray(response.data)) {
                    setNavigations(response.data);
                    setLoading(false);
                }
            })
            .catch(error => {
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.error) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                setMessage(resMessage);
            });
    }, []);

    useEffect(() => {
        setLoading(true);

        axios.get(`${API}/admin/navigation/queryCompany.php`)
            .then(response => {
                if (Array.isArray(response.data)) {
                    setCompanyOptions(response.data);
                    setLoading(false);
                }
            })
            .catch(error => {
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.error) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                setMessage(resMessage);
            });
    }, []);

    return (
        <>
            {message && (
                <Modal
                    message={message}
                    setMessage={setMessage}
                />
            )}

            {loading ? (
                <div className="w3-section">
                    <p>Loading...</p>
                </div> 
            ) : (
                <div className="w3-section w3-border">
                    {navigations.map((navigation, index) => (
                        <Form key={navigation.id} onSubmit={(e) => updateUser(e, navigation.id)}>
                            <div
                                className={`w3-bar ${
                                    expandIndices.includes(index) || index === navigation.length - 1 ? '' : 'w3-border-bottom'
                                } w3-light-grey`}
                                key={navigation.id}
                            >
                                <span className="w3-bar-item" onClick={() => toggleExpand(index)}>{navigation.label} {navigation.category ? `(${navigation.category})` : ``}</span>
                                <span className="w3-bar-item w3-button w3-hover-text-theme w3-right" onClick={() => toggleExpand(index)}>
                                    <i
                                        className={`fa ${
                                            expandIndices.includes(index) ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down'
                                        } w3-large`}
                                    ></i>
                                </span>
                                {expandIndices.includes(index) && (
                                <div className="w3-bar">
                                    <div className={`w3-container ${index !== navigation.length - 1 ? 'w3-border-bottom' : ''} w3-padding-16`}>                                 
                                            <label htmlFor="company">Unternehmen:</label>
                                            <select
                                                className="w3-input"
                                                type="name"
                                                name="company"
                                                onChange={(e) => onChangeCompanies(e, navigation.id)}
                                                value={selectedCompany[navigation.id]}
                                                multiple
                                            >
                                            {companyOptions.map(option => (
                                                <option key={option.id} value={option.id} >{option.name}</option>
                                            ))}
                                            </select>  
                                            <button className="w3-button w3-theme w3-margin-top" type="submit" disabled={loading}>
                                            {!loading && (
                                                <i className="fa fa-save fa-fw w3-margin-right"></i>
                                            )}
                                            {loading && (
                                                <i className="fa fa-spinner fa-spin fa-fw w3-margin-right"></i>
                                            )}
                                            <span>Speichern</span>
                                            </button>
                                    </div>
                                </div>
                                )}
                            </div>
                        </Form>
                    ))}
                </div>
            )}
        </>
    );
};

export { NavigationChange };