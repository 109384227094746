import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

const NavItem = ({ item, onClick, onSidebarToggle , isActive }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();

    const handleItemClick = () => {
        if (!item.items) {
            onClick(item.label);
            navigate(item.link);
            onSidebarToggle(); // Close the sidebar if it's a top-level item
        } else {
            setIsExpanded(!isExpanded); // Toggle expansion for category items
        }
    };

    return (
        <>
            <button
                onClick={handleItemClick}
                className={`w3-bar-item w3-button w3-padding w3-hover-text-theme w3-wide w3-border-bottom ${isActive ? 'w3-text-theme' : ''}`}
            >
                <i className={item.iconClass + " fa-fw w3-margin-right"}></i>
                <b>{item.label}</b>
                {item.items && (
                    <i className={`fa ${isExpanded ? 'fa-minus-square-o' : 'fa-plus-square-o'} fa-fw w3-margin-left w3-right w3-padding-small`}></i>
                )}
            </button>

            {item.items && isExpanded && (
                <div className="w3-animate-left">
                    {item.items.map((subItem, index) => (
                        <NavLink
                            key={subItem.label}
                            to={subItem.link}
                            onClick={() => {
                                onClick(subItem.label);
                                onSidebarToggle(); // Close the sidebar for nested items
                            }}
                            className={`w3-bar-item w3-button w3-padding w3-hover-text-theme ${isActive ? 'w3-text-theme' : ''}  ${index === item.items.length - 1 ? 'w3-border-bottom' : ''}`}
                        >
                            <i className={subItem.iconClass + " fa-fw w3-margin-right"}></i>
                            {subItem.label}
                        </NavLink>
                    ))}
                </div>
            )}
        </>
    );
};

export { NavItem };
