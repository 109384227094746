import axios from "axios";
import { API } from "./apiUrl";

const API_URL = API + "auth/";

const resetPassword = (email) => {
  return axios.post(API_URL + "resetPw.php", {
    email,
  });
};

const login = (email, password) => {
  return axios
    .post(API_URL + "login.php", {
      email,
      password,
    })
    .then((response) => {
      if (response.data) {
        localStorage.setItem("token", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("token");
};

const getCurrentUser = () => { // hole die infos aus dem token  
    const token = JSON.parse(localStorage.getItem("token"));
    return axios
        .post(API_URL + "token.php", {
            token,
        })
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        })
        .catch((error) => {
          // Check if the error response status code is 500 and data is "Expired token"
          if (error.response && error.response.status === 500 && error.response.data === "Expired token") {
            // Remove token from local storage
            localStorage.removeItem("token");
        }})
};

const getCurrentUserNavigation = ( user ) => {
    return axios
        .post(API_URL + "userNavigation.php", {
            user,
        })
        .then((response) => {
            if (response.data.token) {
                localStorage.setItem("token", JSON.stringify(response.data));
            }
            return response.data;
    });
}

const getLogo = ( user ) => {
    return axios
        .post(API_URL + "getLogo.php", {
            user,
        })
        .then((response) => {
            if (response.data.token) {
                localStorage.setItem("token", JSON.stringify(response.data));
            }
            return response.data;
    });
}

const AuthService = {
  resetPassword,
  login,
  logout,
  getCurrentUser,
  getCurrentUserNavigation,
  getLogo,
}

export default AuthService;