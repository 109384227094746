import React from 'react';

const HeaderButton = ({ onClick, className, iconClass, title }) => {
  return (
    <span className={`w3-button ${className}`} onClick={onClick} title={title}>
      <i className={`fa ${iconClass} w3-large`}></i>
    </span>
  );
};

export { HeaderButton };