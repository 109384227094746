import React, { useState, useEffect } from "react";

const Home = () => {
  return (
    <>
    <div className="w3-container">
      <h2>Home</h2>
      <p>Test</p>
    </div>
    </>
  );
};

export default Home;