import { useState, useEffect } from "react";
import { API } from "../services/apiUrl";
import axios from "axios";
import { Modal } from "./utility/modal";

const Ticket = ({toggleTicket, currentUser}) => {

    const [loading, setLoading] = useState(false);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [message, setMessage] = useState("");

    const [formValues, setFormValues] = useState({});

    useEffect(() => {
        axios.post(`${API}/admin/user/queryCompany.php`, {
            currentUser
        })
            .then(response => {
                if (Array.isArray(response.data) && response.data.length > 0) {
                    setCompanyOptions(response.data);
                    const initialPartyId = response.data[0]['party_id'].toString();
                    setFormValues(prevFormValues => ({
                        ...prevFormValues,
                        partyId: initialPartyId,
                        email: currentUser.email
                    }));
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    try {
                        const responseData = JSON.parse(error.response.data);
                        const { status, title } = responseData;
                        setMessage(`Fehler beim Abrufen der E-Mail-Optionen: ${status} - ${title}`);
                    } catch (parseError) {
                        console.error('Fehler beim Parsen der Antwortdaten:', parseError);
                    }
                } else {
                    setMessage('Fehler beim Abrufen der E-Mail-Optionen:', error);
                }
            });

    }, []); 

    const createTicket = (e) => {
        e.preventDefault();
    
        setLoading(true);

        axios.post(`${API}/misc/ticket.php`, 
            JSON.stringify(formValues)
        )
        .then(response => {
            setLoading(false);
            setMessage('Suc-'+response.data);

            setTimeout(() => {
                toggleTicket();
            }, 3000);
        })
        .catch(error => {
            const resMessage =
                (error.response &&
                error.response.data &&
                error.response.data.detail) ||
                error.message ||
                error.toString();
            setLoading(false);
            setMessage(resMessage);
        })       
    }

    const onChangeFormValues = (e) => {
        const { name, value } = e.target;
        setFormValues(prevFormValues => ({
            ...prevFormValues,
            [name]: value
        }));
    };

    return (
        <>
        {message && (
            <Modal message={message} setMessage={setMessage}/>
        )}
            <div className={`w3-modal w3-show`}>
                <div className="w3-modal-content">
                    <div className="w3-container">
                        <span className="w3-button w3-display-topright" onClick={ toggleTicket }>&times;</span>
                        <h2>Ticketerstellung</h2>
                        <form onSubmit={ createTicket }>
                            <div className="w3-section">
                                <label htmlFor="subject">Betreff:</label>
                                <input 
                                    className="w3-input w3-margin-bottom" 
                                    type="text" 
                                    name="subject" 
                                    onChange={onChangeFormValues}
                                    required
                                />
                                <label htmlFor="description">Beschreibung:</label>
                                <textarea 
                                    className="w3-input w3-margin-bottom" 
                                    type="text" 
                                    name="description"
                                    rows={8}
                                    style={{resize: 'none'} }
                                    onChange={onChangeFormValues}
                                    required
                                />
                                <label htmlFor="partyId">Firma:</label>
                                <select 
                                    className="w3-input w3-margin-bottom" 
                                    name="partyId"
                                    value={formValues.partyId || (companyOptions.length > 0 ? companyOptions[0].party_id : '')}
                                    disabled={companyOptions.length === 1}
                                    onChange={onChangeFormValues}
                                >
                                    {companyOptions.map(option => (
                                    <option key={option.id} value={option.party_id} >{option.name}</option>
                                    ))}
                                </select>
                                <label htmlFor="email">E-Mail:</label>
                                <input 
                                    className="w3-input w3-margin-bottom" 
                                    type="mail" 
                                    name="email"
                                    defaultValue={formValues.email}
                                    disabled={true}
                                />
                            </div>
                            <div className="w3-section">
                                <button className="w3-button w3-theme" type="submit" disabled={loading}>
                                {!loading && (
                                    <i className="fa fa-save fa-fw w3-margin-right"></i>
                                )}
                                {loading && (
                                    <i className="fa fa-spinner fa-spin fa-fw w3-margin-right"></i>
                                )}
                                <span>Erstellen</span>
                                </button>
                                <button className="w3-button w3-theme w3-right" onClick={(e) => { toggleTicket() ; e.preventDefault()}}>
                                    <i className="fa fa-close fa-fw w3-margin-right"></i>
                                    <span>Abbrechen</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export { Ticket };