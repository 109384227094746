import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API } from '../../services/apiUrl';

const HeaderAvatar = ({ className = '', height, currentUser, title, cursor }) => {
  const [userImage, setUserImg] = useState('/avatar.png');

  useEffect(() => {
    axios.post(`${API}auth/getAvatar.php`, {
        currentUser
    })
    .then(response => {
        setUserImg(response.data)
    })
    .catch(error => {
        console.error(error)
    })
  }, []);

  return (
      <img 
        src={userImage} 
        className={`w3-circle ${className}`} 
        style={{height: height, cursor: cursor}} 
        title={title}/>
  );
};

export { HeaderAvatar };