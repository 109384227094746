import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API } from '../../../services/apiUrl';
import useCursor from '../../utility/useCursor';
import { Modal } from '../../utility/modal';

const BlanketPurchaseOrderInactive = ({currentUser, frame}) => {

    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(false);
    const [tables, setTables] = useState({ [frame]: [] });
    const [changedStates, setChangedStates] = useState({});
    const [loadingCrosshair, setLoadingCrosshair] = useState(false);
    const [message, setMessage] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = currentPage * itemsPerPage;
    const totalPages = Math.ceil(tables[frame]?.length / itemsPerPage);

    const handleChangePage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const getActualIndex = (pageIndex) => {
        return startIndex + pageIndex;
    };    

    useEffect(() => {
        setLoadingInitial(true);
        axios.post(`${API}mkg/blanketPurchaseOrderList.php`, {
            methode: 'GET',
            active: false
        })
        .then(response => {
            setTables(prevTables => ({
                    ...prevTables,
                    ...formatData(response.data),
            })); 
        })
        .catch(error => {
            const resMessage =
                (error.response &&
                error.response.data &&
                error.response.data.detail) ||
                error.message ||
                error.toString();
            const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
            const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
            setMessage(errorMessage);
        })
        .finally(
            setLoadingInitial(false)
            )
    }, []);

    const formatData = (data) => {
        const formattedData = {};
        data.forEach(item => {
            const type = item.type;
            if (!formattedData[frame]) {
                formattedData[frame] = [];
            }
            formattedData[frame].push(item);
        });
        return formattedData;
    };

    const convertFormatData = (data) => {
        const convertedData = {};
        data[frame].forEach(item => {
            const type = item.type;
            if (!convertedData[type]) {
                convertedData[type] = [];
            }
            convertedData[type].push(item);
        });
        return convertedData;
    };

    useCursor(loadingCrosshair);

    const handleInputChange = (e, rowIndex, fieldName) => {
        const { value, checked } = e.target;
        const actualIndex = getActualIndex(rowIndex);

        if (fieldName === 'active') {
            const activeValue = checked ? 1 : 0;
            setTables(prevTables => {
                const updatedRows = [...prevTables[frame]];
                updatedRows[actualIndex][fieldName] = activeValue;
                return {
                    ...prevTables,
                    [frame]: updatedRows,
                };
            });
        } else {
            setTables(prevTables => {
                const updatedRows = [...prevTables[frame]];
                updatedRows[actualIndex][fieldName] = value;
                return {
                    ...prevTables,
                    [frame]: updatedRows,
                };
            });
        }
        setChangedStates(prevStates => ({
            ...prevStates,
            [frame]: true,
        }));

    };

    const saveChanges = () => {
        setLoading(true);
        axios.post(`${API}mkg/blanketPurchaseOrderList.php`, {
            methode: 'UPDATE',
            data: convertFormatData(tables),
            currentUser
        })
        .then(response => {
                const filteredData = response.data.filter(item => item.active === 0);
                setTables(formatData(filteredData));
                setChangedStates({});
                setMessage('Suc-Die neuen Rahmenverträge wurden erfolgreich gespeichert.')
            })
        .catch(error => {
            const resMessage =
                (error.response &&
                error.response.data &&
                error.response.data.detail) ||
                error.message ||
                error.toString();
            const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
            const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
            setMessage(errorMessage);
            })
        .finally(
            setLoading(false)
            )
    };

    function formatDate(dateString) {
        const [year, month, day] = dateString.split('-');
        return `${day}.${month}.${year}`;
    }

    function formatNumber(numberString) {
        const number = parseFloat(numberString);
        return number.toLocaleString('de-DE', { minimumFractionDigits: 2 });
    }

    const typeTranslations = {
        'aluminum': 'Aluminium',
        'steel': 'Stahl',
        // Add more translations as needed
    };

    return (
        <>
        {message && (
        <Modal message={message} setMessage={setMessage}/>
        )}
        {loadingInitial ? (    
            <div className="w3-section w3-display-container" style={{height: '200px'}}>
                <div className="w3-display-middle">
                    <i className='fa fa-spinner fa-spin w3-margin-right w3-large'></i>
                </div>    
            </div> 
        ) : (
            <>
                {changedStates[frame] && (
                <div className="w3-section w3-mobile" style={{height: '38.5px'}}>
                    <button 
                        className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right`} 
                        style={{
                            pointerEvents: loading ? 'none' : 'auto'
                        }}
                        onClick={saveChanges}
                    >
                        {!loading ? (
                            <>
                                <i className='fa fa-save w3-margin-right w3-small'></i>
                            </>
                        ) : (
                            <>
                                <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                            </>
                        )}
                        Speichern
                    </button>
                </div>
                )}     
            
            <div className="w3-section w3-mobile">
                <table className="w3-table-all w3-bordered w3-border" style={{tableLayout: 'auto'}}>
                    <thead>
                        <tr key={'header'+frame} className='w3-light-grey'>
                            <th style={{width: 'auto'}}>Nummer</th>
                            <th style={{width: 'auto'}}>Lieferant</th>
                            <th style={{width: 'auto'}}>Ursprungsmenge</th>
                            <th style={{width: 'auto'}}>Laufzeit von</th>
                            <th style={{width: 'auto'}}>Laufzeit bis</th>
                            <th style={{width: 'auto'}}>Preis</th>
                            <th style={{width: 'auto'}}>Restmenge</th>
                            <th style={{width: 'auto'}}>Typ</th>
                            <th style={{width: 'auto'}}>Bemerkung</th>
                            <th style={{width: 'auto'}}>Aktiv</th>
                        </tr>
                    </thead>
                    <tbody>
                    {tables[frame]?.slice(startIndex, endIndex).map((row, index) => (
                        <React.Fragment key={`${frame}-${index}`}>
                        <tr key={index} className='w3-white'>
                            <td style={{textAlign: 'right'}}>
                                {row.id}
                            </td>
                            <td style={{textAlign: 'left'}}>
                                {row.supplier}
                            </td>
                            <td style={{textAlign: 'right'}}>
                                {formatNumber(row.quantity)}
                            </td>
                            <td style={{textAlign: 'right'}}>
                                {formatDate(row.startDate)}                                
                            </td>
                            <td style={{textAlign: 'right'}}>
                                {formatDate(row.endDate)}
                            </td>
                            <td style={{textAlign: 'right'}}>
                                {formatNumber(row.price)}
                            </td>
                            <td style={{textAlign: 'right'}}>
                                {formatNumber(row.remainingQuantity)}
                            </td>
                            <td style={{textAlign: 'right'}}>
                                {typeTranslations[row.type] || row.type}
                            </td>
                            <td style={{textAlign: 'right'}}>
                                {row.note}
                            </td>
                            <td style={{padding: '0 0'}} className='w3-display-container'>
                                <input
                                    className='w3-input w3-border-0 w3-white w3-display-middle'
                                    type="checkbox"
                                    checked={row.active}
                                    onChange={(e) => handleInputChange(e, index, 'active')}
                                />
                            </td>
                        </tr>                        
                        </React.Fragment>
                    ))}  
                    </tbody>
                </table>
            </div>   
            {tables[frame]?.length > itemsPerPage && (
            <div className="w3-section w3-mobile">
                <button
                    className="w3-button w3-theme"
                    onClick={() => handleChangePage(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    {'<'}
                </button>
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        className={`w3-button ${currentPage === index + 1 ? 'w3-black' : 'w3-theme'}`}
                        onClick={() => handleChangePage(index + 1)}
                    >
                        {index + 1}
                    </button>
                ))}
                <button
                    className="w3-button w3-theme"
                    onClick={() => handleChangePage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    {'>'}
                </button>
            </div> 
            )}       
            </>
        )}
        </>
    );
};

export { BlanketPurchaseOrderInactive };