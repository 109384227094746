import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API } from '../../../services/apiUrl.jsx';
import { Modal } from "../../utility/modal.jsx";
import { InputForm } from "../../utility/form.jsx";
import { TooltipIcon } from '../../utility/tooltipIcon.jsx';

const ArticlePictureUpload = ({ currentUser }) => {
    
    const dateStringToTimestamp = (dateString) => {
        const [year, month, day] = dateString.split('-').map(Number);
        const dateObject = new Date(year, month - 1, day);
        return dateObject.getTime();
    };

    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(false);
    const [changedStates, setChangedStates] = useState(false);

    const [data, setData] = useState({
        tenant: '',
        apiKey: '',
        file: null,
    });
    const [dataOriginal, setDataOriginal] = useState(data);
    const [errors, setErrors] = useState({});

    const [streamData, setStreamData] = useState([]);

    const saveChanges = () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setLoading(true);

            const formData = new FormData();
            formData.append('file', data.file);
            formData.append('tenant', data.tenant);
            formData.append('apiKey', data.apiKey);

            axios.post(`${API}sgbit/articlePictureUpload.php`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                const { uniqueId } = response.data;

                if (uniqueId) {
                    const eventSource = new EventSource(`${API}sgbit/articlePictureUpload.php?uniqueId=${uniqueId}&startSSE=1`);
                    eventSource.onmessage = (event) => {
                        const newData = JSON.parse(event.data);
                        if (newData.message === "File processing complete!") {
                            setStreamData(prevData => [...prevData, newData.message]);
                            eventSource.close();

                            setData({
                                ...dataOriginal, 
                                file: null
                            });
                            setErrors({});
                            setChangedStates(false);
                            setLoading(false)
                        } else {
                            setStreamData(prevData => [...prevData, newData.message]);
                        }
                    };
                    eventSource.onerror = (event) => {
                        setStreamData(prevData => [...prevData, "Es gab einen Fehler bei der Übertragung."]);
                        eventSource.close();
                        setLoading(false);
                    };  
                } else {
                    setMessage('Die Informationen konnten nicht erfolgreich übermittelt werden.');
                    setLoading(false);
                }
            })
            .catch(error => {
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.detail) ||
                    error.message ||
                    error.toString();
                const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
                const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
                setMessage(errorMessage);
                setLoading(false);
            });
        }
    };

    const resetChanges = () => {
        setData({
            ...dataOriginal, 
            file: null
        });
        setStreamData([]);
        setErrors({});
        setChangedStates(false);
        setLoading(false)
    };

    useEffect(() => {
        const dataIsEqual = JSON.stringify(data) === JSON.stringify(dataOriginal);
        if (dataIsEqual) {
            setChangedStates(false);
        } else {
            setChangedStates(true);
        }
    }, [data]);

    const handleInputChange = (e, key, type) => {
        const value = type === 'file' ? e.target.files[0] : 
                      type === 'date' ? dateStringToTimestamp(e.target.value) : 
                      e.target.value;
        setData(prevState => ({ ...prevState, [key]: value }));
        setErrors({ ...errors, [key]: null });
    };

    const fields = [
        { key: 'tenant', type: 'text', placeholder: 'Tenant', icon: 'fa fa-building-o' },
        { key: 'apiKey', type: 'text', placeholder: 'API Key', icon: 'fa fa-key' },
        { key: 'file', type: 'file', placeholder: 'Import-Datei', icon: 'fa fa-file-excel-o' },
    ];

    const validateFields = () => {
        const newErrors = {};
        const apiKeyRegex = /^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/i;
        const csvRegex = /\.csv$/i;

        if (!data.file || !data.file.name || !csvRegex.test(data.file.name)) {
            newErrors.file = 'Lade eine CSV-Datei hoch';
        }
        if (!data.tenant || data.tenant.trim() === '') {
            newErrors.tenant = 'Gib einen Tenant an';
        }
        if (!data.apiKey || !apiKeyRegex.test(data.apiKey)) {
            newErrors.apiKey = 'Gib einen gültigen API-Key an';
        }

        return newErrors;
    };

    return (            
            <div style={{ position: 'relative' }}>
            {message && (
            <Modal message={message} setMessage={setMessage}/>
            )}
            {loadingInitial ? (    
                <div className="w3-section w3-display-container" style={{height: '200px'}}>
                    <div className="w3-display-middle">
                        <i className='fa fa-spinner fa-spin w3-margin-right w3-large'></i>
                    </div>    
                </div> 
            ) : (
                <React.Fragment>
                <div className="w3-section w3-mobile w3-bar">
                    <div className={`w3-left w3-bar-item`} >
                        <TooltipIcon>
                            <i
                                className='fa fa-info-circle w3-xlarge w3-text-theme'
                                style={{cursor: 'help'}}
                            />
                            <h4>Artikelbilder</h4>
                            <p>
                                Mit diesem Upload-Tool kannst du Artikelbilder an vorhandene Artikel hochladen. 
                                Dafür notwendig sind die Artikelnummern, die URL zum Bild und der gwünschte Name des Bildes.<br/> 
                                Die Vorlage muss wie folgt aussehen:
                            </p>
                            <table
                                style={{width: '100%', borderCollapse: 'collapse', wordBreak: 'break-word', margin: '10px 0'}}
                            >
                                <tbody>
                                <tr>
                                    <td className='w3-border'>articleNumber</td>
                                    <td className='w3-border'>picture</td>
                                    <td className='w3-border'>pictureName</td>
                                    <td className='w3-border'>pictureMain</td>
                                </tr>
                                <tr>
                                    <td className='w3-border'>Artikelnummer</td>
                                    <td className='w3-border'>URL zum Bild (png)</td>
                                    <td className='w3-border'>Name des Bildes</td>
                                    <td className='w3-border'>yes / no</td>
                                </tr>
                                </tbody>
                            </table>
                        </TooltipIcon>
                    </div>
                    {changedStates && (
                    <React.Fragment>
                    <button 
                        className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`} 
                        style={{
                            pointerEvents: loading ? 'none' : 'auto'
                        }}
                        onClick={saveChanges}
                    >
                        {!loading ? (
                            <>
                                <i className='fa fa-save w3-margin-right w3-small'></i>
                            </>
                        ) : (
                            <>
                                <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                            </>
                        )}
                        Speichern
                    </button>
                    <button 
                        className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`} 
                        style={{
                            pointerEvents: loading ? 'none' : 'auto',
                            marginRight: '8px'
                        }}
                        onClick={resetChanges}
                    >
                        {!loading ? (
                            <>
                                <i className='fa fa-save w3-margin-right w3-small'></i>
                            </>
                        ) : (
                            <>
                                <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                            </>
                        )}
                        Zurücksetzen
                    </button>
                    </React.Fragment>
                    )}     
                </div>
                <InputForm
                    data={data} 
                    fields={fields} 
                    errors={errors}
                    handleInputChange={handleInputChange} 
                />
                <div className="w3-section w3-mobile w3-bar">
                    <h4>Dokumentation</h4>                   
                    <textarea
                        className='w3-input w3-border'
                        name='documentation'
                        readOnly
                        rows={14}
                        style={{resize: 'none', overflowY: 'scroll'}}
                        value={streamData.join('\n')}
                    />
                </div>
                </React.Fragment>
                )
            }
            </div>       
    );
};

export { ArticlePictureUpload };