import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API } from '../../../services/apiUrl';
import { Modal } from '../../utility/modal';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";

const UserUpdate = ( {currentUser} ) => {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);
    const [message, setMessage] = useState("");
    const [expandIndices, setExpandIndices] = useState([]);
    const [apikey, setApikey] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState({});

    const onChangeRole = (e, userId) => {
        const selectedRoleId = parseInt(e.target.value);
        setSelectedRoles(prevRoles => ({
            ...prevRoles,
            [userId]: selectedRoleId
        }));
        setUsers(prevUsers => prevUsers.map(user => {
            if (user.user_id === userId) {
                let newRoles = [...user.roles];
                // Remove roles with higher ID than the selected role
                newRoles = newRoles.filter(role => role.role_id <= selectedRoleId);
                // Add the selected role if it doesn't exist in the user's roles
                const roleExists = newRoles.some(role => role.role_id === selectedRoleId);
                if (!roleExists) {
                    const selectedRole = roleOptions.find(option => option.role_id === selectedRoleId);
                    if (selectedRole) {
                        newRoles.push(selectedRole);
                    }
                }
                return { ...user, roles: newRoles };
            }
            return user;
        }));
    };

    const onChangeApiKey = (e, userId) => {
        const newApikey = e.target.value;
        setApikey(prevApikey => ({
            ...prevApikey,
            [userId]: newApikey
        }));
        setUsers(prevUsers => prevUsers.map(user => {
            if (user.user_id === userId) {
                return { ...user, api_key: newApikey };
            }
            return user;
        }));
    };

    const updateUser = (e, userId) => {
        e.preventDefault();
    
        setMessage("");
        setLoading(true);

        const userToUpdate = users.find(user => user.user_id === userId);

        axios.post(`${API}/admin/user/updateUser.php`, {
            userToUpdate
        })
        .then(response => {
            setLoading(false);
            setMessage('Suc-'+response.data);
        })
        .catch(error => {
            const resMessage =
                (error.response &&
                error.response.data &&
                error.response.data.error) ||
                error.message ||
                error.toString();
            setLoading(false);
            setMessage(resMessage);
        })
    };

    const toggleExpand = (index) => {
        setExpandIndices(prevIndices => {
            if (prevIndices.includes(index)) {
                return prevIndices.filter(i => i !== index);
            } else {
                return [...prevIndices, index];
            }
        });
    };

    useEffect(() => {
        setLoading(true);

        axios.post(`${API}/admin/user/queryUsersUpdate.php`, {
            currentUser,
        })
            .then(response => {
                if (Array.isArray(response.data)) {
                    setUsers(response.data);
                    setLoading(false);
                }
            })
            .catch(error => {
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.error) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                setMessage(resMessage);
            });
    }, []);

    useEffect(() => {
        setLoading(true);

        axios.get(`${API}/admin/user/queryRoles.php`)
            .then(response => {
                if (Array.isArray(response.data)) {
                    setRoleOptions(response.data);
                    setLoading(false);
                }
            })
            .catch(error => {
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.error) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                setMessage(resMessage);
            });
    }, []);

    return (
        <>
            {message && (
                <Modal
                    message={message}
                    setMessage={setMessage}
                />
            )}

            {loading ? (
                <div className="w3-section">
                    <p>Loading...</p>
                </div> 
            ) : (
                <div className="w3-section w3-border">
                    {users.map((user, index) => (
                        <Form key={user.user_id} onSubmit={(e) => updateUser(e, user.user_id)}>
                            <div
                                className={`w3-bar ${
                                    expandIndices.includes(index) || index === users.length - 1 ? '' : 'w3-border-bottom'
                                } w3-light-grey`}
                                key={user.user_id}
                            >
                                <span className="w3-bar-item" onClick={() => toggleExpand(index)}>{user.email} ({user.company_name})</span>
                                <span className="w3-bar-item w3-button w3-hover-text-theme w3-right" onClick={() => toggleExpand(index)}>
                                    <i
                                        className={`fa ${
                                            expandIndices.includes(index) ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down'
                                        } w3-large`}
                                    ></i>
                                </span>
                                {expandIndices.includes(index) && (
                                <div className="w3-bar">
                                    <div className={`w3-container ${index !== users.length - 1 ? 'w3-border-bottom' : ''} w3-padding-16`}>                                 
                                            <label htmlFor="userrole">Rolle:</label>
                                            <Select
                                                className="w3-input"
                                                type="name"
                                                name="userrole"
                                                onChange={(e) => onChangeRole(e, user.user_id)}
                                                value={selectedRoles[user.user_id] || Math.max(...user.roles.map(role => role.role_id))}
                                            >
                                            {roleOptions.map(option => (
                                                <option key={option.role_id} value={option.role_id} >{option.role_name}</option>
                                            ))}
                                            </Select>  
                                            <label htmlFor="apikey">API-Key:</label>
                                            <Input 
                                                className="w3-input" 
                                                placeholder={user.api_key}
                                                type="name" 
                                                name="apikey" 
                                                value={apikey[user.user_id]} 
                                                onChange={(e) => onChangeApiKey(e, user.user_id)}
                                            />
                                            <button className="w3-button w3-theme w3-margin-top" type="submit" disabled={loading}>
                                            {!loading && (
                                                <i className="fa fa-save fa-fw w3-margin-right"></i>
                                            )}
                                            {loading && (
                                                <i className="fa fa-spinner fa-spin fa-fw w3-margin-right"></i>
                                            )}
                                            <span>Speichern</span>
                                            </button>
                                    </div>
                                </div>
                                )}
                            </div>
                        </Form>
                    ))}
                </div>
            )}
        </>
    );
};

export { UserUpdate };


