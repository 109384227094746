import React, { useState, useEffect, useRef } from 'react';
import { format, startOfWeek, addDays, addWeeks, differenceInHours, parseISO } from 'date-fns';
import { de } from 'date-fns/locale';
import { toInt } from 'validator';
import axios from 'axios';
import { weclappShirtivalAPI } from '../../../services/apiUrl';

const ProductionPlanCalenderForeign = ({ includeWeekend = false, setChangedStates, productionPlan, setProductionPlan, productionUnplanned, setProductionUnplanned, initialProductionPlan, setInitialProductionPlan, initialProductionUnplanned, setInitialProductionUnplanned, customAttributes, productionPlace, machine = '', message, setMessage, salesChannel, currentUser }) => {
    const [partyData, setPartyData] = useState({});
    const [partyDataUnplanned, setPartyDataUnplanned] = useState({});
    const [loading, setLoading] = useState(false);
    const [weekOffset, setWeekOffset] = useState(0);
    const generateWeekDays = () => {
        const start = startOfWeek(addWeeks(new Date(), weekOffset), { weekStartsOn: 1 }); // Monday as start
        const days = Array.from({ length: includeWeekend ? 7 : 5 }, (_, i) => addDays(start, i));
        return days;
    };
    const goToPreviousWeek = () => setWeekOffset((prev) => prev - 1);
    const goToNextWeek = () => setWeekOffset((prev) => prev + 1);
    const weekDays = generateWeekDays();
    const [calendarWidth, setCalendarWidth] = useState('auto');
    useEffect(() => {
        const calendarElement = document.getElementById('calendar');
    
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                const { width } = entry.contentRect;
                setCalendarWidth(width);
            }
        });

        if (calendarElement) {
            resizeObserver.observe(calendarElement);
    
            // Initial size update
            const { width } = calendarElement.getBoundingClientRect();
            setCalendarWidth(width);
    
            return () => {
                resizeObserver.disconnect(); // Clean up observer on unmount
            };
        }
    }, []);
    const widthStyle = {
        width: calendarWidth  < (includeWeekend ? 1000 : 720) 
            ? '100%' 
            : (calendarWidth - 16) / (includeWeekend ? 8 : 6),
        height: calendarWidth  < (includeWeekend ? 1000 : 720)
            ? '400px'
            : '648.5px',
        transition: 'width 0.3s ease' // Optional: adds a smooth transition effect
    };

    const [heights, setHeights] = useState([]); // Store calculated heights
    const dayRefs = useRef([]);
    useEffect(() => {
        // Function to calculate heights
        const calculateHeights = () => {
            const newHeights = dayRefs.current.map(ref => ref ? ref.getBoundingClientRect().height : 0);
            setHeights(newHeights);
        };

        // Calculate heights after render
        calculateHeights();

        // Optionally, listen for window resize events
        window.addEventListener('resize', calculateHeights);
        return () => {
            window.removeEventListener('resize', calculateHeights);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${weclappShirtivalAPI}/salesOrder`, {
                    params: {
                        [`or-customAttribute${customAttributes.produktionsplanung}-null`] : '',
                        [`or-customAttribute${customAttributes.produktionsplanungOrt}.value-null`]: '',
                        [`customAttribute${customAttributes.produktionenErstellt}-eq`]: true,
                        'shipped-eq' : false,
                        // 'status-eq': 'ORDER_CONFIRMATION_PRINTED',
                        [`customAttribute${customAttributes.payedTime}-notnull`]: '',
                        'properties': 'id,orderNumber,customAttributes,customerId,salesChannel,shipped'
                    },
                    headers: {
                        AuthenticationToken: currentUser.api_key
                    }
                });
                const fetchedData = response.data.result;
                const orderNumbers = fetchedData.map(order => order.orderNumber);
                const productionOrdersResponse = await Promise.all(
                    orderNumbers.map(async (orderNumber) => {
                        try {
                            const productionResponse = await axios.get(`${weclappShirtivalAPI}/productionOrder`, {
                                params: {
                                    'productionOrderNumber-like': "%"+orderNumber,
                                    'status-ne': 'CANCELLED',
                                    'properties': 'picksComplete,productionOrderNumber,status,customAttributes'
                                },
                                headers: {
                                    AuthenticationToken: currentUser.api_key
                                }
                            });
                            const results = productionResponse.data.result;
                            if (!results || results.length === 0) return null; 
                            let combinedPicksComplete = true;
                            let combinedProductionOrderNumber = '';
                            let combinedCustomAttributes = {
                                [customAttributes.pickFolien]: true,
                                [customAttributes.pickTextilien]: true,
                                [customAttributes.druckAbgeschlossen]: true
                            };
                            results.forEach(order => {
                                combinedPicksComplete = combinedPicksComplete && order.picksComplete;
                                const orderNumberPart = order.productionOrderNumber.split('-')[1];
                                combinedProductionOrderNumber = orderNumberPart; // It should be identical across all orders
                                order.customAttributes.forEach(attribute => {
                                    if (attribute.attributeDefinitionId === customAttributes.pickFolien) {
                                        const booleanValue = attribute.booleanValue !== undefined ? attribute.booleanValue : false;
                                        combinedCustomAttributes[customAttributes.pickFolien] = combinedCustomAttributes[customAttributes.pickFolien] && booleanValue;
                                    }
                
                                    if (attribute.attributeDefinitionId === customAttributes.pickTextilien) {
                                        const booleanValue = attribute.booleanValue !== undefined ? attribute.booleanValue : false;
                                        combinedCustomAttributes[customAttributes.pickTextilien] = combinedCustomAttributes[customAttributes.pickTextilien] && booleanValue;
                                    }

                                    if (attribute.attributeDefinitionId === customAttributes.druckAbgeschlossen) {
                                        const booleanValue = attribute.booleanValue !== undefined ? attribute.booleanValue : false;
                                        combinedCustomAttributes[customAttributes.druckAbgeschlossen] = combinedCustomAttributes[customAttributes.druckAbgeschlossen] && booleanValue;
                                    }
                                });
                            });
                            return {
                                productionPicksComplete: combinedPicksComplete,
                                orderNumber: combinedProductionOrderNumber,
                                productionStatus: results[0].status, // Status can be taken from the first order as they should be identical
                                customAttributes: [
                                    {
                                        attributeDefinitionId: customAttributes.pickFolien,
                                        booleanValue: combinedCustomAttributes[customAttributes.pickFolien]
                                    },
                                    {
                                        attributeDefinitionId: customAttributes.pickTextilien,
                                        booleanValue: combinedCustomAttributes[customAttributes.pickTextilien]
                                    },
                                    {
                                        attributeDefinitionId: customAttributes.druckAbgeschlossen,
                                        booleanValue: combinedCustomAttributes[customAttributes.druckAbgeschlossen]
                                    }
                                ]
                            };
                        } catch (error) {
                            console.error(`Error fetching production order for ${orderNumber}`, error);
                            return null;
                        }
                    })
                );
                const productionOrders = productionOrdersResponse.filter(order => order !== null);    
                const customerIds = fetchedData.map(order => order.customerId);
                const partyResponse = await Promise.all(
                    customerIds.map(async (customerId) => {
                        try {
                            const partyRes = await axios.get(`${weclappShirtivalAPI}/party`, {
                                params: {
                                    'id-eq': customerId,
                                    'properties': 'id,firstName,lastName,company,company2'
                                },
                                headers: {
                                    AuthenticationToken: currentUser.api_key
                                }
                            });
                            return partyRes.data.result[0];
                        } catch (error) {
                            console.error(`Error fetching customer data for ${customerId}`, error);
                            return null;
                        }
                    })
                );
                const partyData = partyResponse.filter(party => party !== null);
                const mergedData = fetchedData.map(fetchedOrder => {
                    const productionOrderMap = productionOrders.reduce((map, productionOrder) => {
                        map[productionOrder.orderNumber] = productionOrder;
                        return map;
                    }, {});
                    const productionOrder = productionOrderMap[fetchedOrder.orderNumber];
                    if (productionOrder) {
                        fetchedOrder.productionPicksComplete = productionOrder.productionPicksComplete;
                        fetchedOrder.productionStatus = productionOrder.productionStatus;
                        const pickFolienAttr = productionOrder.customAttributes.find(attr => attr.attributeDefinitionId === customAttributes.pickFolien);
                        const pickTextilienAttr = productionOrder.customAttributes.find(attr => attr.attributeDefinitionId === customAttributes.pickTextilien);
                        const druckAbgeschlossenAttr = productionOrder.customAttributes.find(attr => attr.attributeDefinitionId === customAttributes.druckAbgeschlossen);
                        fetchedOrder.customAttributes = fetchedOrder.customAttributes.map(attr => {
                            if (attr.attributeDefinitionId === customAttributes.pickFolien && pickFolienAttr) {
                                return { ...attr, booleanValue: pickFolienAttr.booleanValue };
                            } else if (attr.attributeDefinitionId === customAttributes.pickTextilien && pickTextilienAttr) {
                                return { ...attr, booleanValue: pickTextilienAttr.booleanValue };
                            } else if (attr.attributeDefinitionId === customAttributes.druckAbgeschlossen && druckAbgeschlossenAttr) {
                                return { ...attr, booleanValue: druckAbgeschlossenAttr.booleanValue };
                            }
                            return attr;
                        });
                        if (!fetchedOrder.customAttributes.some(attr => attr.attributeDefinitionId === customAttributes.pickFolien) && pickFolienAttr) {
                            fetchedOrder.customAttributes.push({
                                attributeDefinitionId: customAttributes.pickFolien,
                                booleanValue: pickFolienAttr.booleanValue
                            });
                        }
                        if (!fetchedOrder.customAttributes.some(attr => attr.attributeDefinitionId === customAttributes.pickTextilien) && pickTextilienAttr) {
                            fetchedOrder.customAttributes.push({
                                attributeDefinitionId: customAttributes.pickTextilien,
                                booleanValue: pickTextilienAttr.booleanValue
                            });
                        }
                        if (!fetchedOrder.customAttributes.some(attr => attr.attributeDefinitionId === customAttributes.druckAbgeschlossen) && druckAbgeschlossenAttr) {
                            fetchedOrder.customAttributes.push({
                                attributeDefinitionId: customAttributes.druckAbgeschlossen,
                                booleanValue: druckAbgeschlossenAttr.booleanValue
                            });
                        }
                    }
                    return fetchedOrder;
                });
                setInitialProductionUnplanned((prevData) => ({
                    ...prevData,
                    ...mergedData
                }));
                setProductionUnplanned((prevData) => ({
                    ...prevData,
                    ...mergedData
                }));
                setPartyData(prevData => {
                    const updatedData = { ...prevData };
                    partyData.forEach(newParty => {
                        const idExists = Object.values(updatedData).some(existingParty => existingParty.id === newParty.id);
                        if (!idExists) {
                            const nextIndex = Object.keys(updatedData).length;
                            updatedData[nextIndex] = newParty;
                        }
                    });
                    return updatedData;
                });
                setPartyDataUnplanned(prevData => {
                    const updatedData = { ...prevData };
                    partyData.forEach(newParty => {
                        const idExists = Object.values(updatedData).some(existingParty => existingParty.id === newParty.id);
                        if (!idExists) {
                            const nextIndex = Object.keys(updatedData).length;
                            updatedData[nextIndex] = newParty;
                        }
                    });
                    return updatedData;
                });
            } catch (error) {
                const resMessage = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
                setMessage(resMessage);
            } finally {
                setLoading(false); // Ensure loading state is updated regardless of success or error
            }
        };
        fetchData();
    }, []);

    // Function to handle API call when week changes
    const fetchDataForWeek = async (startDay, endDay) => {
        setLoading(true);
        try {
            const response  = await axios.get(`${weclappShirtivalAPI}/salesOrder`, {
                params: {
                    [`customAttribute${customAttributes.produktionsplanung}-ge`]: parseISO(format(startDay, 'yyyy-MM-dd')).getTime(),
                    [`customAttribute${customAttributes.produktionsplanung}-le`]: parseISO(format(endDay, 'yyyy-MM-dd')).getTime(),
                    [`customAttribute${customAttributes.produktionsplanungOrt}.id-eq`]: productionPlace.id,
                    [`customAttribute${customAttributes.produktionenErstellt}-eq`]: true,
                    'status-ne': 'CANCELLED',
                    'status-ne': 'MANUALLY_CLOSED',
                    'properties': 'id,orderNumber,customAttributes,customerId,salesChannel,shipped'
                },
                headers: {
                    AuthenticationToken: currentUser.api_key
                }
            });
            const fetchedData = response.data.result;
        
            // Make a call for each orderNumber to get the productionOrders
            const orderNumbers = fetchedData.map(order => order.orderNumber);
            const thirdResponse = await Promise.all(
                orderNumbers.map(async (orderNumber) => {
                    try {
                        const thirdResponse = await axios.get(`${weclappShirtivalAPI}/productionOrder`, {
                            params: {
                                'productionOrderNumber-like': "%"+orderNumber,
                                'status-ne': 'CANCELLED',
                                'properties': 'picksComplete,productionOrderNumber,status,customAttributes'
                            },
                            headers: {
                                AuthenticationToken: currentUser.api_key
                            }
                        });
                        const results = thirdResponse.data.result;
                        if (!results || results.length === 0) {
                            return null; // Handle case with no results
                        }
                        let combinedPicksComplete = true; // Start as true because we want AND
                        let combinedProductionOrderNumber = '';
                        let combinedCustomAttributes = {
                            [customAttributes.pickFolien]: true,
                            [customAttributes.pickTextilien]: true 
                        };
                        results.forEach(order => {
                            combinedPicksComplete = combinedPicksComplete && order.picksComplete;
                            const orderNumberPart = order.productionOrderNumber.split('-')[1];
                            combinedProductionOrderNumber = orderNumberPart; // It should be identical across all orders
                            order.customAttributes.forEach(attribute => {
                                if (attribute.attributeDefinitionId === customAttributes.pickFolien) {
                                    const booleanValue = attribute.booleanValue !== undefined ? attribute.booleanValue : false;
                                    combinedCustomAttributes[customAttributes.pickFolien] = combinedCustomAttributes[customAttributes.pickFolien] && booleanValue;
                                }
            
                                if (attribute.attributeDefinitionId === customAttributes.pickTextilien) {
                                    const booleanValue = attribute.booleanValue !== undefined ? attribute.booleanValue : false;
                                    combinedCustomAttributes[customAttributes.pickTextilien] = combinedCustomAttributes[customAttributes.pickTextilien] && booleanValue;
                                }

                                if (attribute.attributeDefinitionId === customAttributes.druckAbgeschlossen) {
                                    const booleanValue = attribute.booleanValue !== undefined ? attribute.booleanValue : false;
                                    combinedCustomAttributes[customAttributes.druckAbgeschlossen] = combinedCustomAttributes[customAttributes.druckAbgeschlossen] && booleanValue;
                                }
                            });
                        });
                        return {
                            productionPicksComplete: combinedPicksComplete,
                            orderNumber: combinedProductionOrderNumber,
                            productionStatus: results[0].status, // Status can be taken from the first order as they should be identical
                            customAttributes: [
                                {
                                    attributeDefinitionId: customAttributes.pickFolien,
                                    booleanValue: combinedCustomAttributes[customAttributes.pickFolien]
                                },
                                {
                                    attributeDefinitionId: customAttributes.pickTextilien,
                                    booleanValue: combinedCustomAttributes[customAttributes.pickTextilien]
                                },
                                {
                                    attributeDefinitionId: customAttributes.druckAbgeschlossen,
                                    booleanValue: combinedCustomAttributes[customAttributes.druckAbgeschlossen]
                                }
                            ]
                        };
                    } catch (error) {
                        console.error(`Error fetching production order for ${orderNumber}`, error);
                        return null
                    }
                })
            );
            const productionOrders = thirdResponse.filter(order => order !== null);

            // Make a call for each customerId to get the informations
            const customerIds = fetchedData.map(order => order.customerId);
            const fourthResponse = await Promise.all(
                customerIds.map(async (customerId) => {
                    try {
                        const fourthResponse = await axios.get(`${weclappShirtivalAPI}/party`, {
                            params: {
                                'id-eq': customerId,
                                'properties': 'id,firstName,lastName,company,company2'
                            },
                            headers: {
                                AuthenticationToken: currentUser.api_key
                            }
                        });
                        return fourthResponse.data.result[0];
                    } catch (error) {
                        console.error(`Error fetching production order for ${customerId}`, error);
                        return null
                    }
                })
            );
            const party = fourthResponse.filter(order => order !== null);
            setPartyData((prevData) => {
                const updatedData = { ...prevData };
                party.forEach(newParty => {
                    const idExists = Object.values(updatedData).some(existingParty => existingParty.id === newParty.id);
                    if (!idExists) {
                        const nextIndex = Object.keys(updatedData).length;
                        updatedData[nextIndex] = newParty;
                    }
                });
                return updatedData;
            });

            // Merge productionOrders into fetchedData based on orderNumber
            const mergedData = fetchedData.map(fetchedOrder => {
                // Create a map of productionOrders based on the orderNumber for quick lookup
                const productionOrderMap = productionOrders.reduce((map, productionOrder) => {
                    map[productionOrder.orderNumber] = productionOrder;
                    return map;
                }, {});

                // Find the corresponding productionOrder for the current fetchedOrder
                const productionOrder = productionOrderMap[fetchedOrder.orderNumber];

                if (productionOrder) {
                    // Merge non-custom attributes (e.g., productionPicksComplete, productionStatus) into fetchedOrder
                    fetchedOrder.productionPicksComplete = productionOrder.productionPicksComplete;
                    fetchedOrder.productionStatus = productionOrder.productionStatus;
                    // Find the customAttributes in the productionOrder for pickFolien and pickTextilien
                    const pickFolienAttr = productionOrder.customAttributes.find(attr => attr.attributeDefinitionId === customAttributes.pickFolien);
                    const pickTextilienAttr = productionOrder.customAttributes.find(attr => attr.attributeDefinitionId === customAttributes.pickTextilien);
                    const druckAbgeschlossenAttr = productionOrder.customAttributes.find(attr => attr.attributeDefinitionId === customAttributes.druckAbgeschlossen);

                    // Update fetchedData's customAttributes if found in productionOrder
                    fetchedOrder.customAttributes = fetchedOrder.customAttributes.map(attr => {
                        if (attr.attributeDefinitionId === customAttributes.pickFolien && pickFolienAttr) {
                            return { ...attr, booleanValue: pickFolienAttr.booleanValue };
                        } else if (attr.attributeDefinitionId === customAttributes.pickTextilien && pickTextilienAttr) {
                            return { ...attr, booleanValue: pickTextilienAttr.booleanValue };
                        } else if (attr.attributeDefinitionId === customAttributes.druckAbgeschlossen && druckAbgeschlossenAttr) {
                            return { ...attr, booleanValue: druckAbgeschlossenAttr.booleanValue };
                        }
                        return attr;
                    });

                    // If customAttributes for pickFolien or pickTextilien don't exist in fetchedOrder, add them
                    if (!fetchedOrder.customAttributes.some(attr => attr.attributeDefinitionId === customAttributes.pickFolien) && pickFolienAttr) {
                        fetchedOrder.customAttributes.push({
                            attributeDefinitionId: customAttributes.pickFolien,
                            booleanValue: pickFolienAttr.booleanValue
                        });
                    }
                    if (!fetchedOrder.customAttributes.some(attr => attr.attributeDefinitionId === customAttributes.pickTextilien) && pickTextilienAttr) {
                        fetchedOrder.customAttributes.push({
                            attributeDefinitionId: customAttributes.pickTextilien,
                            booleanValue: pickTextilienAttr.booleanValue
                        });
                    }
                    if (!fetchedOrder.customAttributes.some(attr => attr.attributeDefinitionId === customAttributes.druckAbgeschlossen) && druckAbgeschlossenAttr) {
                        fetchedOrder.customAttributes.push({
                            attributeDefinitionId: customAttributes.druckAbgeschlossen,
                            booleanValue: druckAbgeschlossenAttr.booleanValue
                        });
                    }
                }

                return fetchedOrder;
            });

            const structuredData = mergedData.reduce((acc, order) => {
                // Get the dateValue from customAttributes
                const dateAttribute = order.customAttributes.find(attr => attr.attributeDefinitionId === customAttributes.produktionsplanung); // Ensure this ID matches your actual definition ID
                const dateValue = dateAttribute ? dateAttribute.dateValue : null;
                if (dateValue) {
                    // Convert dateValue from Unix timestamp to a readable date format
                    const formattedDate = format(new Date(dateValue), 'yyyy-MM-dd');           
                    // Initialize productionPlace in the accumulator if it doesn't exist
                    if (!acc[productionPlace.id]) {
                        acc[productionPlace.id] = {};
                    }   
                    // Structure the data under the formatted date
                    if (!acc[productionPlace.id][formattedDate]) {
                        acc[productionPlace.id][formattedDate] = []; // Initialize an array if it doesn't exist
                    }      
                    // Add the order to the appropriate productionPlace and date
                    acc[productionPlace.id][formattedDate].push(order);
                }
                return acc;
            }, {});
            // Update the initialProductionPlan with the fetched data
            setInitialProductionPlan((prevData) => {
                return {
                    ...prevData,
                    [productionPlace.id]: {
                        ...prevData[productionPlace.id], // Preserve existing productionPlace data
                        ...structuredData[productionPlace.id], // Merge new data for this productionPlace
                    }
                };
            });
            setProductionPlan((prevData) => {
                const prevPlaceData = prevData[productionPlace.id] || {};
                const newPlaceData = structuredData[productionPlace.id] || {};
            
                const mergedData = { ...prevPlaceData };
            
                for (const date in newPlaceData) {
                    // Filter out any duplicate orders by their ID
                    const existingOrders = prevPlaceData[date] || [];
                    const newOrders = newPlaceData[date] || [];
            
                    const filteredNewOrders = newOrders.filter(
                        newOrder => !existingOrders.some(order => order.id === newOrder.id)
                    );
            
                    mergedData[date] = [...existingOrders, ...filteredNewOrders];
                }
            
                return {
                    ...prevData,
                    [productionPlace.id]: mergedData,
                };
            });
        } catch (error) {
            const resMessage = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
            const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
            const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
            setMessage(errorMessage);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const startDay = weekDays[0]; // First day of the week (Monday)
        const endDay = weekDays[weekDays.length - 1]; // Last day of the week (Friday/Sunday depending on weekend)


        console.log('Production Plan before merge:', productionPlan);
        fetchDataForWeek(startDay, endDay);
        console.log('Production Plan after merge:', productionPlan);
    }, [weekOffset, productionPlace, machine]);

    const getCustomAttributeValue = (order, attributeId) => {
        const attribute = order.customAttributes.find(attr => attr.attributeDefinitionId === attributeId);
        if (!attribute) return null;
    
        // Check for the type of value (numberValue, stringValue, booleanValue, dateValue, etc.)
        if (attribute.numberValue) return attribute.numberValue;
        if (attribute.stringValue) return attribute.stringValue;
        if (attribute.booleanValue !== undefined) return attribute.booleanValue;
        if (attribute.dateValue) return new Date(attribute.dateValue).toLocaleDateString(); // Convert to readable date
        return null;
    };

    const getClassForOrder = (order) => {
        // i. Auftrag ist hellgrün: Textilien und Folie vorhanden
        // ii. Auftrag ist gelb: Textilien oder Folie fehlt oder Teillieferung Textilien
        // iii. Auftrag ist rot: Nichts ist vorhanden
        // iv. Auftrag ist grau: Produktion abgeschlossen
        // v. Auftrag ist knallgrün: Auftrag ist versendet
        // vi. Sortierung der Farben (erledigte Aufträge nach ganz unten, gelb und rot nach ganz oben)
        const shipped = order.shipped === true;
        const productionFinished = getCustomAttributeValue(order, customAttributes.druckAbgeschlossen) === true || order.productionStatus === 'CLOSED';
        const pickFolien = getCustomAttributeValue(order, customAttributes.pickFolien) === true;
        const pickTextilien = getCustomAttributeValue(order, customAttributes.pickTextilien) === true;
        let className = "";

        // v. Auftrag ist knallgrün: Auftrag ist versendet
        if (shipped) {
            className += " w3-green"; // Add a red background if it's urgent
        }
        // iv. Auftrag ist grau: Produktion abgeschlossen
        if (!shipped && productionFinished) {
            className += " w3-gray"; // Add bold class for high textile count
        }
        // iii. Auftrag ist rot: Nichts ist vorhanden
        if (!shipped && !productionFinished && !pickFolien && !pickTextilien) {
            className += " w3-red"; // Add bold class for high textile count
        }
        // ii. Auftrag ist gelb: Textilien oder Folie fehlt oder Teillieferung Textilien
        if (!shipped && !productionFinished && (pickFolien || pickTextilien)) {
            className += " w3-yellow"; // Add bold class for high textile count
        }
        // i. Auftrag ist hellgrün: Textilien und Folie vorhanden
        if (!shipped && !productionFinished && pickFolien && pickTextilien) {
            className += " w3-light-green"; // Add bold class for high textile count
        }
    
        // Add more conditions and classes as needed
        return className;
    };

    const getSalesChannelName = (key) => {
        const channel = salesChannel.find(channel => channel.key === key);
        return channel ? channel.name : key; // Return name if found, otherwise return key itself
    };

    const getPartyName = (key) => {
        if (Object.keys(partyData).length > 0) {
            // Convert the object values to an array
            const parties = Object.values(partyData);
            const party = parties.find(party => party.id === key);
            
            // Return the desired string based on conditions
            return party 
                ? (party.lastName ? `${party.firstName} ${party.lastName}` : party.company) 
                : key; // Return the key if no party is found
        }
        
        // If partyData is empty, just return the key
        return key;
    };

    const handleDragStart = (e, order) => {
        e.dataTransfer.setData('order', JSON.stringify(order)); // Store order data
    };

    const handleDrop = (e, formattedDate) => {
        e.preventDefault();
        const draggedOrder = JSON.parse(e.dataTransfer.getData('order'));
    
        // Create a copy of the dragged order to modify
        const updatedOrder = { ...draggedOrder };
    
        // Check if the dragged order is currently in productionPlan
        let isInProductionPlan = false;
        let oldDateKey = null;
    
        // Loop through productionPlan to find the order and its previous date
        for (const placeId in productionPlan) {
            for (const date in productionPlan[placeId]) {
                const orders = productionPlan[placeId][date];
                const orderIndex = orders.findIndex(order => order.id === updatedOrder.id);
    
                if (orderIndex !== -1) {
                    isInProductionPlan = true;
                    oldDateKey = date; // Save the old date where the order is currently located
                    break; // No need to continue searching
                }
            }
            if (isInProductionPlan) break; // Break if found
        }
    
        // Logic for dropping the order into productionUnplanned
        if (formattedDate === 'unplanned') { // Use a specific identifier for productionUnplanned
            if (isInProductionPlan) {
                // If the order is currently in productionPlan, we need to remove it from there
                setProductionPlan(prevData => {
                    const updatedPlan = { ...prevData };
    
                    // Remove the order from its old date
                    if (oldDateKey) {
                        updatedPlan[productionPlace.id][oldDateKey] = updatedPlan[productionPlace.id][oldDateKey].filter(order => order.id !== updatedOrder.id);
                        if (updatedPlan[productionPlace.id][oldDateKey].length === 0) {
                            delete updatedPlan[productionPlace.id][oldDateKey]; // Clean up if no orders left
                        }
                    }
                    return updatedPlan;
                });
    
                // Update custom attributes to set values to null
                updatedOrder.customAttributes.forEach(attr => {
                    if (attr.attributeDefinitionId === customAttributes.produktionsplanungOrt) {
                        attr.selectedValueId = null; // Set selectedValueId to null
                    }
                    if (attr.attributeDefinitionId === customAttributes.produktionsplanung) {
                        attr.dateValue = null; // Set dateValue to null
                    }
                    if (attr.attributeDefinitionId === customAttributes.produktionsplanungPresse) {
                        attr.selectedValueId = null; // Update selectedValueId
                    }
                });
    
                // Add the order back to productionUnplanned
                setProductionUnplanned(prevData => {
                    const newIndex = Object.keys(prevData).length;
                    return {
                        ...prevData,
                        [newIndex]: updatedOrder // Assuming prevData is structured to allow using order id as key
                    }
                });
            }
        } else if (isInProductionPlan) {
            // Existing logic for moving within productionPlan (as implemented earlier)
            // Update customAttributes
            updatedOrder.customAttributes.forEach(attr => {
                if (attr.attributeDefinitionId === customAttributes.produktionsplanungOrt) {
                    attr.selectedValueId = productionPlace.id; // Update selectedValueId
                }
                if (attr.attributeDefinitionId === customAttributes.produktionsplanung) {
                    const dateValueInMs = new Date(formattedDate).getTime();
                    attr.dateValue = dateValueInMs; // Update dateValue to the new date in ms
                }
                if (attr.attributeDefinitionId === customAttributes.produktionsplanungPresse) {
                    attr.selectedValueId = machine; // Update selectedValueId
                }
            });
    
            // Remove the order from its old date
            setProductionPlan(prevData => {
                const updatedPlan = { ...prevData };
                if (oldDateKey) {
                    updatedPlan[productionPlace.id][oldDateKey] = updatedPlan[productionPlace.id][oldDateKey].filter(order => order.id !== updatedOrder.id);
                    if (updatedPlan[productionPlace.id][oldDateKey].length === 0) {
                        delete updatedPlan[productionPlace.id][oldDateKey]; // Clean up if no orders left
                    }
                }
                // Add to the new date
                if (!updatedPlan[productionPlace.id]) {
                    updatedPlan[productionPlace.id] = {};
                }
                if (!updatedPlan[productionPlace.id][formattedDate]) {
                    updatedPlan[productionPlace.id][formattedDate] = [];
                }
                updatedPlan[productionPlace.id][formattedDate].push(updatedOrder); // Add updated order to new date
                return updatedPlan;
            });
        } else {
            // Existing logic for dragging from productionUnplanned to productionPlan
            // Remove from productionUnplanned if it's not in productionPlan
            setProductionUnplanned(prevData => Object.values(prevData).filter(order => order.id !== updatedOrder.id));
    
            updatedOrder.customAttributes.forEach(attr => {
                if (attr.attributeDefinitionId === customAttributes.produktionsplanungOrt) {
                    attr.selectedValueId = productionPlace.id; // Update selectedValueId
                }
                if (attr.attributeDefinitionId === customAttributes.produktionsplanung) {
                    const dateValueInMs = new Date(formattedDate).getTime();
                    attr.dateValue = dateValueInMs; // Update dateValue to the new date in ms
                }
                if (attr.attributeDefinitionId === customAttributes.produktionsplanungPresse) {
                    attr.selectedValueId = machine; // Update selectedValueId
                }
            });

            // Add to the appropriate productionPlan date
            setProductionPlan(prevData => {
                const updatedPlan = { ...prevData };
                if (!updatedPlan[productionPlace.id]) {
                    updatedPlan[productionPlace.id] = {};
                }
                if (!updatedPlan[productionPlace.id][formattedDate]) {
                    updatedPlan[productionPlace.id][formattedDate] = [];
                }
                updatedPlan[productionPlace.id][formattedDate].push(updatedOrder);
                return updatedPlan;
            });
        }
    };

    useEffect(() => {
        console.log(initialProductionPlan)
    }, [initialProductionPlan]);

    // useEffect(() => {
    //     console.log(initialProductionUnplanned)
    // }, [initialProductionUnplanned]);

    // useEffect(() => {
    //     console.log(productionPlan)
    // }, [productionPlan]);

    // useEffect(() => {
    //     console.log(productionUnplanned)
    // }, [productionUnplanned]);

    useEffect(() => {
        setPartyData(partyDataUnplanned)
    }, [machine, productionPlace]);

    useEffect(() => {
        const hasProductionPlanChanged = JSON.stringify(productionPlan) !== JSON.stringify(initialProductionPlan);
        const hasProductionUnplannedChanged = JSON.stringify(productionUnplanned) !== JSON.stringify(initialProductionUnplanned);

        if (hasProductionPlanChanged || hasProductionUnplannedChanged) {
            setChangedStates(true);
        }
    }, [productionPlan,productionUnplanned]);

    return (
        
        <React.Fragment>
            <div className="w3-section w3-mobile">
                {/* Pagination controls for previous and next week */}
                <button
                    className={`w3-button w3-theme`}
                    onClick={goToPreviousWeek}
                >
                    Vorherige Woche
                    <i className='fa fa-chevron-left w3-margin-left'></i>
                </button>
                <button
                    className={`w3-button w3-theme w3-right`}
                    onClick={goToNextWeek}
                >
                    <i className='fa fa-chevron-right w3-margin-right'></i>
                    Nächste Woche
                </button>
            </div>
            <div className="w3-row-padding w3-section" id='calendar'>
                {/* Ungeplante Aufträge */}
                <div key='unplannedSalesOrders' className="w3-col w3-mobile w3-center" style={widthStyle} onDragOver={(e) => e.preventDefault()} onDrop={(e) => handleDrop(e, 'unplanned')}>
                    <div className="w3-border" style={{height: heights[0]}}>
                    {productionUnplanned ? (
                        Object.values(productionUnplanned).map((order) => (
                            <div 
                                key={order.id} 
                                className={`w3-border w3-padding w3-small ${getClassForOrder(order)}`}
                                draggable
                                onDragStart={(e) => handleDragStart(e, order)}
                            >
                                {/* Display order */}
                                Auftrag: {order.orderNumber} - {getPartyName(order.customerId)}<br/>
                                {getCustomAttributeValue(order, customAttributes.gesamtanzahlTextilien)} Teile - ZEIT<br/>
                                Deadline: {getCustomAttributeValue(order, customAttributes.deadline)}<br/>
                                Vertriebsweg: {getSalesChannelName(order.salesChannel)}<br/>
                            </div>
                        ))
                    ) : (
                        loading ? <div><i className='fa fa-spinner fa-spin w3-large w3-margin-top'></i></div> : <div></div>
                    )}
                    </div>
                </div>
                {/* Geplante Aufträge */}
                {weekDays.map((day, index) => {
                    const formattedDate = format(day, 'yyyy-MM-dd');

                    const ordersForTheDay = productionPlan[productionPlace.id]?.[formattedDate] ?? [];
            
                    // Calculate Anzahl Aufträge
                    const orderCount = ordersForTheDay.length;
            
                    // Calculate Anzahl Textilien
                    const textileCount = ordersForTheDay.reduce((total, order) => {
                        const textileAttribute = order.customAttributes.find(attr => attr.attributeDefinitionId === customAttributes.gesamtanzahlTextilien);
                        const numberValue = textileAttribute ? parseInt(textileAttribute.numberValue, 10) : 0;
                        return total + numberValue;
                    }, 0);
            
                    return (
                    <div 
                        key={index} 
                        className="w3-col w3-mobile w3-center" 
                        style={widthStyle} ref={el => dayRefs.current[index] = el}
                        onDragOver={(e) => {
                            e.preventDefault(); // Necessary to allow dropping
                        }} 
                        onDrop={(e) => handleDrop(e, formattedDate)}
                    >
                        {/* Display date and day name */}
                        <div className="w3-border">
                            <strong>{format(day, 'EEEE', { locale: de })}</strong>
                            <br/>{format(day, 'dd.MM.yyyy', { locale: de })}
                        </div>
                        {/* Display Datainformation */}
                        <div className="w3-border w3-margin-top">
                            <ul className="w3-ul">
                                <li className="w3-display-container w3-border-0" style={{padding: '0px 0px'}}>Anzahl Aufträge: {orderCount}</li>
                                <li className="w3-display-container w3-border-0" style={{padding: '0px 0px'}}>Anzahl Textilien: {textileCount}</li>
                                <li className="w3-display-container w3-border-0" style={{padding: '0px 0px'}}>Anzahl Drucke: </li>
                            </ul>
                        </div>
                        {/* Time slots for each day */}
                        <div className="w3-border w3-margin-top" style={{ height: calendarWidth < (includeWeekend ? 1000 : 720) ? '200px' : '500px'}}>                             
                        {productionPlan[productionPlace.id] && productionPlan[productionPlace.id][format(day, 'yyyy-MM-dd')] ? (
                            productionPlan[productionPlace.id][format(day, 'yyyy-MM-dd')].map((order) => (
                                <div 
                                    key={order.id}
                                    className={`w3-border w3-padding w3-small ${getClassForOrder(order)}`}
                                    draggable // Make the order draggable
                                    onDragStart={(e) => handleDragStart(e, order)}
                                    style={{height: calendarWidth < (includeWeekend ? 1000 : 720) ? (200 / orderCount)+'px' : (500 / orderCount)+'px', overflow: 'scroll', scrollbarWidth: 'none'}}
                                >
                                    {/* Display order details */}
                                    Auftrag: {order.orderNumber} - {getPartyName(order.customerId)}<br/>
                                    {getCustomAttributeValue(order, customAttributes.gesamtanzahlTextilien)} Teile - ZEIT<br/>
                                    Deadline: {getCustomAttributeValue(order, customAttributes.deadline)}<br/>
                                    Vertriebsweg: {getSalesChannelName(order.salesChannel)}<br/>
                                </div>
                            ))
                        ) : (
                            loading ? <div><i className='fa fa-spinner fa-spin w3-large w3-margin-top'></i></div> : <div></div>
                        )}   
                        </div>
                    </div>
                    );
                })}
            </div>
        </React.Fragment>
    );
};

export { ProductionPlanCalenderForeign };
