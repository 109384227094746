import React, { useState } from 'react';
import { HeaderButton } from './utility/HeaderButton.jsx'
import { HeaderAvatar } from './utility/HeaderAvatar.jsx';
import { NavLink, useNavigate } from 'react-router-dom';

const Header = ({ toggleSidebar, toggleTicket, currentUser }) => { 

    const handlePhoneClick = () => {
        window.location.href = 'tel:0049461310930';
    };

    const handleMailClick = () => {
        window.location.href = 'mailto:weclapp@sgb-it.de';
    };

    return (
        <header className="w3-container">
        <div className="w3-section w3-border-bottom w3-padding-16 w3-cell-row">
            <div className="w3-cell w3-cell-middle">
                <HeaderButton
                    className="w3-hide-large w3-large w3-hover-text-theme w3-margin-right"
                    onClick={toggleSidebar}
                    iconClass="fa-bars"
                />
                <HeaderButton
                    className="w3-button w3-margin-right w3-hover-text-theme"
                    onClick={handlePhoneClick}
                    iconClass="fa fa-phone w3-large"
                    title={'Anrufen'}
                />
                <HeaderButton
                    className="w3-button w3-margin-right w3-hover-text-theme"
                    onClick={toggleTicket}
                    iconClass="fa fa-ticket w3-large"
                    title={'Ticket erstellen'}
                />
                <HeaderButton
                    className="w3-button w3-margin-right w3-hover-text-theme"
                    onClick={handleMailClick}
                    iconClass="fa fa-envelope w3-large"
                    title={'E-Mail schreiben'}
                />
            </div>
            <div className='w3-right'>
                <NavLink to='/user'>
                    <HeaderAvatar currentUser={currentUser} height='65px' title={'Benutzer bearbeiten'} cursor='pointer'/>
                </NavLink>
            </div>
        </div>
      </header>
    );
};
  
export { Header };