import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API } from '../../../services/apiUrl';
import useCursor from '../../utility/useCursor';
import { Modal } from '../../utility/modal';

const ShirtivalMachineEdit = ( {currentUser, frame = 'machines'} ) => {
    
    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(false);
    const [tables, setTables] = useState({ [frame]: [] });
    const [initialTables, setInitialTables] = useState({ [frame]: [] });
    const [editableRows, setEditableRows] = useState([]);
    const [changedStates, setChangedStates] = useState({});
    const [durchschnitt, setDurchschnitt] = useState([]);
    const [loadingCrosshair, setLoadingCrosshair] = useState(false);
    const [message, setMessage] = useState("");
    const [errorRows, setErrorRows] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage] = useState(10);

    // Paginate rows
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = (tables[frame] || []).slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil((tables[frame]?.length || 0) / rowsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        setLoadingInitial(true);
        axios.post(`${API}shirtival/productionPlaningMachineEdit.php`, { methode: 'GET' })
            .then(response => {
                const sortedData = response.data.sort((a, b) => a.machine_id - b.machine_id);
                const formattedData = formatData(sortedData);
                setTables(prevTables => ({
                    ...prevTables,
                    ...formattedData,
                }));
                setInitialTables(JSON.parse(JSON.stringify(formattedData)));
                setLoadingInitial(false);
            })
            .catch(error => {
                const resMessage = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
                const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
                const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
                setMessage(errorMessage);
                setLoadingInitial(false);
            });
    }, []);

    const formatData = (data) => {
        const formattedData = {};
        data.forEach(item => {
            if (!formattedData[frame]) {
                formattedData[frame] = [];
            }
            formattedData[frame].push(item);
        });
        return formattedData;
    };

    useCursor(loadingCrosshair);

    useEffect(() => {
        setTables(prevTables => ({
            ...prevTables,
            [frame]: prevTables[frame] || [],
        }));
        setChangedStates(prevStates => ({
            ...prevStates,
            [frame]: prevStates[frame] || false
        }));
        setDurchschnitt(prevOptions => ({
            ...prevOptions,
            [frame]: prevOptions[frame] || 0,
        }));
    }, [frame]);

    const handleAddRow = () => {
        const newRow = {
            machine_id: '',
            name: '',
        };
        setTables(prevTables => {
            const updatedRows = [newRow, ...(prevTables[frame] || [])];  // Ensure prevTables[frame] is defined
            return {
                ...prevTables,
                [frame]: updatedRows,
            };
        });
    
        setEditableRows(prevIndices => {
            const currentIndices = prevIndices[frame] || [];  // Ensure prevIndices[frame] is defined
            return {
                ...prevIndices,
                [frame]: [0, ...currentIndices.map(index => index + 1)]  // Adjust indices correctly
            };
        });
    
        setChangedStates(prevStates => ({
            ...prevStates,
            [frame]: true
        }));
    };

    const handleRemoveRow = (index) => {
        setTables(prevTables => {
            const updatedTables = { ...prevTables };
            updatedTables[frame] = prevTables[frame].filter((_, i) => i !== index);
            return updatedTables;
        });
        setChangedStates(prevStates => ({
            ...prevStates,
            [frame]: true
        }));
    };

    const handleInputChange = (e, rowIndex, fieldName) => {
        const { value } = e.target;
        
        setTables(prevTables => {
            const updatedRows = (prevTables[frame] || []).map((row, index) =>
                index === rowIndex ? { ...row, [fieldName]: value } : row
            );
            return {
                ...prevTables,
                [frame]: updatedRows,
            };
        });

        setChangedStates(prevStates => ({
            ...prevStates,
            [frame]: true,
        }));
    };

    const saveChanges = () => {
        setErrorRows([]);
        const rowsWithError = [];
        for (const type in tables) {
            for (const [index, item] of tables[type].entries()) {
                if (item.hasOwnProperty('machine_id') && item['machine_id'] === '') {
                    const valuesToCheck = Object.keys(item).filter(key => key !== 'machine_id');
                    const hasEmptyOrZeroValue = valuesToCheck.some(key => item[key] === '' || item[key] === 0 || item[key] === "0");
                    if (hasEmptyOrZeroValue) {
                        const errorMessage = [];
                        if (hasEmptyOrZeroValue) {
                            errorMessage.push("Fülle bitte alle Felder aus.");
                        }
                        rowsWithError.push({ errorMessage, index });
                    }
                }
            }
        }
        if (rowsWithError.length > 0) {
            setErrorRows(prevState => [...prevState, ...rowsWithError]);
            return;
        }
        setLoading(true);
        setLoadingCrosshair(true);
        axios.post(`${API}shirtival/productionPlaningMachineEdit.php`, {
            methode: 'UPDATE',
            data: tables,
            currentUser
        })
        .then(response => {
            const sortedData = response.data.sort((a, b) => a.machine_id - b.machine_id);
            setTables(formatData(sortedData));
            setChangedStates({});
            setErrorRows([]);
            setEditableRows([]);
            setMessage('Suc-Die Maschinen wurden erfolgreich angepasst.');
            setLoading(false);
            setLoadingCrosshair(false);
        })
        .catch(error => {
            const resMessage = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
            const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
            const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
            setMessage(errorMessage);
            setLoading(false);
            setLoadingCrosshair(false);
        });
    };

    const resetChanges = () => {
        setLoading(true);
        setTables(initialTables);
        setChangedStates({});
        setErrorRows([]);
        setEditableRows([]);
        setCurrentPage(1);
        setLoading(false);
    };

    useEffect(() => {
        const areTablesEqual = JSON.stringify(tables) === JSON.stringify(initialTables);
        if (areTablesEqual) {
            setChangedStates({});
            setErrorRows([]);
            setEditableRows([]);
        }
    }, [tables]);

    const removeRowFromErrors = (rowToRemove) => {
        setErrorRows(prevErrorRows => prevErrorRows.filter(errorRow => errorRow.index !== tables[frame].indexOf(rowToRemove)));
    };

    return (
        <React.Fragment>
            {message && (
                <Modal message={message} setMessage={setMessage} />
            )}
            {loadingInitial ? (
                <div className="w3-section w3-display-container" style={{ height: '200px' }}>
                    <div className="w3-display-middle">
                        <i className='fa fa-spinner fa-spin w3-margin-right w3-large'></i>
                    </div>
                </div>
            ) : (
                <>
                    <div className="w3-section w3-mobile w3-bar">
                        <button className='w3-button w3-theme w3-bar-item' onClick={handleAddRow}>
                            <i className='fa fa-plus w3-margin-right w3-small'></i> Neue Presse
                        </button>
                        {changedStates[frame] && (
                            <React.Fragment>
                                <button
                                    className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`}
                                    style={{ pointerEvents: loading ? 'none' : 'auto' }}
                                    onClick={saveChanges}
                                >
                                    {!loading ? (
                                        <>
                                            <i className='fa fa-save w3-margin-right w3-small'></i>
                                        </>
                                    ) : (
                                        <>
                                            <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                                        </>
                                    )}
                                    Speichern
                                </button>
                                <button
                                    className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right`}
                                    style={{ pointerEvents: loading ? 'none' : 'auto', marginRight: '8px' }}
                                    onClick={resetChanges}
                                >
                                    {!loading ? (
                                        <>
                                            <i className='fa fa-save w3-margin-right w3-small'></i>
                                        </>
                                    ) : (
                                        <>
                                            <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                                        </>
                                    )}
                                    Zurücksetzen
                                </button>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="w3-section w3-mobile">
                        <table className="w3-table-all w3-bordered w3-border" style={{ tableLayout: 'auto' }}>
                            <thead>
                                <tr key={'header'.frame} className='w3-light-grey'>
                                    <th style={{ width: '100%' }}>Name</th>
                                    <th style={{ width: 'auto' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentRows.map((row, index) => (
                                    <React.Fragment key={`${frame}-${index}`}>
                                        <tr key={index} className='w3-white'>
                                            <td style={{ padding: '0 0' }}>
                                                <input
                                                    className='w3-input w3-border-0 w3-white'
                                                    style={{ textAlign: 'left' }}
                                                    type="text"
                                                    value={row.name}
                                                    onChange={(e) => handleInputChange(e, index, 'name')}
                                                    disabled={!editableRows[frame]?.includes(index)}
                                                />
                                            </td>
                                            <td style={{padding: '0 0', verticalAlign: 'middle'}}>
                                                <div className="w3-button w3-hover-text-theme">
                                                    <i 
                                                        className={`fa fa fa-trash w3-large w3-text-theme`}
                                                        onClick={() => handleRemoveRow(index)}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        {errorRows.some(errorRow => errorRow.index === index) &&
                                            <tr key={`error-${index}`}>
                                                <td colSpan={2} className="w3-display-container w3-red">
                                                    <div>
                                                        <span
                                                            onClick={() => removeRowFromErrors(row)}
                                                            className="w3-button w3-display-topright">
                                                            X
                                                        </span>
                                                        {errorRows
                                                            .filter(errorRow => errorRow.index === index)
                                                            .map((errorRow, errorIndex) => (
                                                                <div key={`error-${index}-${errorIndex}`}>
                                                                    {errorRow.errorMessage.map((message, messageIndex) => (
                                                                        <p key={`errorMessage-${index}-${errorIndex}-${messageIndex}`}>{message}</p>
                                                                    ))}
                                                                </div>
                                                        ))}
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="w3-section w3-mobile">
                        <div className="w3-bar">
                            <button
                                className={`w3-button ${currentPage === 1 ? 'w3-disabled' : 'w3-theme'}`}
                                onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                <i className='fa fa-chevron-left'></i>
                            </button>
                            {[...Array(totalPages).keys()].map(pageNumber => (
                                <button
                                    key={pageNumber}
                                    className={`w3-button ${currentPage === pageNumber + 1 ? 'w3-theme' : ''}`}
                                    onClick={() => handlePageChange(pageNumber + 1)}
                                >
                                    {pageNumber + 1}
                                </button>
                            ))}
                            <button
                                className={`w3-button ${currentPage === totalPages ? 'w3-disabled' : 'w3-theme'}`}
                                onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                <i className='fa fa-chevron-right'></i>
                            </button>
                        </div>
                    </div>
                </>
            )}
        </React.Fragment>
    );
};

export { ShirtivalMachineEdit };


