const Modal = ({message, setMessage, onConfirm}) => {

    const toggleModal = () => {
        setMessage("");
    }
    const toggleConfirm = () => {
        setMessage("");
        if (onConfirm) {
            onConfirm(); // Call the onConfirm function if provided
        }
    }


    return (
        <div className={`w3-modal ${message ? 'w3-show' : 'w3-hide'}`} style={{zIndex: '99'}}>
            <div className="w3-modal-content w3-display-middle">
                <div className={`w3-container ${message.startsWith('Suc-') ? 'w3-green' : message.startsWith('Ask-') ? 'w3-blue' : 'w3-red'}`}>
                    <span className="w3-button w3-display-topright" onClick={toggleModal}>&times;</span>
                    <h2>{message.startsWith('Suc-') ? 'Erfolgreich' : message.startsWith('Ask-') ? 'Information' : 'Fehler'}</h2>
                </div>
                <div className="w3-container w3-margin-bottom">
                    <p>{message.startsWith('Suc-') || message.startsWith('Ask-') ? message.substring(4) : message}</p>
                    {message.startsWith('Ask-') && (
                        <>
                        <div className="w3-bar">
                            <span className="w3-bar-item w3-button w3-left w3-green" onClick={toggleConfirm}>
                                <i className="fa fa-check fa-fw w3-large w3-margin-right"></i>Ja
                            </span>
                            <span className="w3-bar-item w3-button w3-right w3-red" onClick={toggleModal}>
                                <i className="fa fa-remove fa-fw w3-large w3-margin-right"></i>Nein
                            </span>
                        </div>

                        </>
                    )}
                </div>
            </div>
        </div>
        );
    };
    
export { Modal };