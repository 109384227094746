import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API } from '../../../services/apiUrl';
import { Modal } from '../../utility/modal';
import { InputFormNoIcons } from '../../utility/formNoIcons';

const ShirtivalConfig = ( {currentUser} ) => {
    
    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(false);
    const [message, setMessage] = useState("");
    const [changedStates, setChangedStates] = useState(false);

    const [data, setData] = useState({});
    const [dataOriginal, setDataOriginal] = useState({});
    const [fields, setFields] = useState([]);
    const [errors, setErrors] = useState({});

    const resetChanges = () => {
        setData({
            ...dataOriginal
        });
        setErrors({});
        setChangedStates(false);
        setLoading(false)
    };

    useEffect(() => {
        setLoadingInitial(true);
        axios.post(`${API}shirtival/productionPlaningConfig.php`, { methode: 'GET' })
        .then(response => {
        // Restructure the fetched data
        const fetchedConfig = response.data;
        // Sort fetchedConfig by position first (assuming 'position' exists in fetchedConfig)
        const sortedConfig = fetchedConfig.sort((a, b) => a.position - b.position); // Sort by position
        // Transform the sorted data into the desired structure
        const transformedFields = sortedConfig.map(item => ({
            key: item.config_key,
            type: item.config_type === 'bool' ? 'checkbox' : item.config_type, // Convert type to 'checkbox' for bool
            placeholder: item.config_placeholder
        }));
        // Set the transformed fields into state
        setFields(transformedFields);
        // Prepare structured data based on transformedFields
        const structuredData = {};
        // Loop through the transformed fields to structure data properly
        transformedFields.forEach(field => {
            const matchedConfig = fetchedConfig.find(config => config.config_key === field.key);
            if (matchedConfig) {
                if (field.type === 'checkbox') {
                    structuredData[field.key] = matchedConfig.config_value.toLowerCase() === 'true'; // Convert string to boolean
                } else {
                    structuredData[field.key] = matchedConfig.config_value; // Keep as is for other types
                }
            }
        });
        // Set the restructured data to state
        setDataOriginal(structuredData);
        setData(structuredData);
        setLoadingInitial(false);
        })
        .catch(error => {
            const resMessage = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
            const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
            const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
            setMessage(errorMessage);
            setLoadingInitial(false);
        });
    }, []);

    useEffect(() => {
        const dataIsEqual = JSON.stringify(data) === JSON.stringify(dataOriginal);
        if (dataIsEqual) {
            setChangedStates(false);
        } else {
            setChangedStates(true);
        }
    }, [data]);

    const handleInputChange = (e, key, type, checkedValue) => {
        const value = type === 'file' ? e.target.files[0] : 
                      type === 'bool' ? checkedValue : 
                      e.target.value;
        setData(prevState => ({ ...prevState, [key]: value }));
        setErrors({ ...errors, [key]: null });
    };

    const validateFields = () => {
        const newErrors = {};
        // Function to check if a string can be converted to a valid number
        const isNumber = (value) => !isNaN(value) && value.trim() !== '';
        // Iterate over the fields in the data object
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const value = data[key];
                // Skip validation for boolean fields
                if (typeof value === 'boolean') {
                    continue;
                }
                // Check if the field is empty
                if (value.trim() === '') {
                    newErrors[key] = `Das Feld darf nicht leer sein`; // Error for empty fields
                }
                // Check if the field contains a valid number (for non-boolean fields)
                else if (!isNumber(value)) {
                    newErrors[key] = `Gib eine gültige Zahl ein`; // Error for invalid numbers
                }
            }
        }
    
        return newErrors;
    };

    const saveChanges = () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setLoading(true);
            axios.post(`${API}shirtival/productionPlaningConfig.php`, {
                methode: 'UPDATE',
                data: data,
                currentUser
            })
            .then(response => {
                // Restructure the fetched data
                const fetchedConfig = response.data;
                // Sort fetchedConfig by position first (assuming 'position' exists in fetchedConfig)
                const sortedConfig = fetchedConfig.sort((a, b) => a.position - b.position); // Sort by position
                // Transform the sorted data into the desired structure
                const transformedFields = sortedConfig.map(item => ({
                    key: item.config_key,
                    type: item.config_type === 'bool' ? 'checkbox' : item.config_type, // Convert type to 'checkbox' for bool
                    placeholder: item.config_placeholder
                }));
                // Set the transformed fields into state
                setFields(transformedFields);
                // Prepare structured data based on transformedFields
                const structuredData = {};
                // Loop through the transformed fields to structure data properly
                transformedFields.forEach(field => {
                    const matchedConfig = fetchedConfig.find(config => config.config_key === field.key);
                    if (matchedConfig) {
                        if (field.type === 'checkbox') {
                            structuredData[field.key] = matchedConfig.config_value.toLowerCase() === 'true'; // Convert string to boolean
                        } else {
                            structuredData[field.key] = matchedConfig.config_value; // Keep as is for other types
                        }
                    }
                });
                // Set the restructured data to state
                setDataOriginal(structuredData);
                setData(structuredData);
                setErrors({});
                setChangedStates(false);
                setLoading(false);
                setMessage('Suc-Die Konfiguration wurden erfolgreich angepasst.');
            })
            .catch(error => {
                const resMessage = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
                const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
                const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
                setMessage(errorMessage);
                setLoading(false);
            });
        }
    }

    return (
        <React.Fragment>
        {message && (
            <Modal 
                message={message}
                setMessage={setMessage}
            />
        )}
        
        {loadingInitial ? (
            <div className="w3-section">Loading...</div>
        ) : ( 
            <React.Fragment>
            <div className="w3-section w3-mobile w3-bar">
                {changedStates && (
                <React.Fragment>
                <button 
                    className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`} 
                    style={{
                        pointerEvents: loading ? 'none' : 'auto'
                    }}
                    onClick={saveChanges}
                >
                    {!loading ? (
                        <>
                            <i className='fa fa-save w3-margin-right w3-small'></i>
                        </>
                    ) : (
                        <>
                            <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                        </>
                    )}
                    Speichern
                </button>
                <button 
                    className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`} 
                    style={{
                        pointerEvents: loading ? 'none' : 'auto',
                        marginRight: '8px'
                    }}
                    onClick={resetChanges}
                >
                    {!loading ? (
                        <>
                            <i className='fa fa-save w3-margin-right w3-small'></i>
                        </>
                    ) : (
                        <>
                            <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                        </>
                    )}
                    Zurücksetzen
                </button>
                </React.Fragment>
                )}     
            </div>
            <InputFormNoIcons
                data={data} 
                fields={fields} 
                errors={errors}
                handleInputChange={handleInputChange} 
            />
            </React.Fragment>     
        )} 
        </React.Fragment>
    );
};

export { ShirtivalConfig };


