import React, { useState } from "react";

const Dashboard = () => {
  return (
    <div className="w3-container">
      <h2>Dashboard</h2>
      <p>Lorem ipsum dola...</p>
    </div>
  );
};

export { Dashboard };
