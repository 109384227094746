import React, { useState, useEffect, useRef } from 'react';
import { TabButton } from '../utility/tabButton.jsx';
import { BlanketPurchaseOrder } from './blanketPurchaseOrder/blanketPurchaseOrder.jsx';
import { BlanketPurchaseOrderInactive } from './blanketPurchaseOrder/blanketPurchaseOrderInactive.jsx';
import { GalvanizingPrice } from './blanketPurchaseOrder/galvanizingPrice.jsx';

const MkgBlanketPurchaseOrder = ({currentUser}) => {

  const [activeComponent, setActiveComponent] = useState('aluminum');

  const renderComponent = (component) => {
    switch (component) {
        case 'aluminum':
            return <BlanketPurchaseOrder currentUser={currentUser} frame={'aluminum'}/>;
        case 'steel':
            return <BlanketPurchaseOrder currentUser={currentUser} frame={'steel'}/>;
        case 'inactive':
            return <BlanketPurchaseOrderInactive currentUser={currentUser} frame={'inactive'}/>;
        case 'price':
            return <GalvanizingPrice currentUser={currentUser} />;
        default:
            return '';
    }
  };

  return (
      <div className="w3-container">
          <h2>Rahmenverträge</h2>
          <div className="w3-section">
              <div className="w3-bar w3-theme">
                  <TabButton onClick={() => setActiveComponent('aluminum')} isActive={activeComponent === 'aluminum'}>Aluminium</TabButton>
                  <TabButton onClick={() => setActiveComponent('steel')} isActive={activeComponent === 'steel'}>Stahl</TabButton>
                  <TabButton onClick={() => setActiveComponent('inactive')} isActive={activeComponent === 'inactive'}>Inaktive</TabButton>
                  <TabButton onClick={() => setActiveComponent('price')} isActive={activeComponent === 'price'}>Verzinkungspreis</TabButton>
              </div>
          </div>
          {renderComponent(activeComponent)}
      </div>
  );
};

export { MkgBlanketPurchaseOrder };