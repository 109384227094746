import React from "react";

const Overlay = ( { isOverlayOpen, toggleOverlay } ) => {

  return (
    <div
      className={`w3-overlay w3-animate-opacity ${isOverlayOpen ? 'w3-show' : 'w3-hide'}`}
      style={{ cursor: "pointer" }}
      onClick={ toggleOverlay }
      title="close side menu"
    ></div>
  );
};

export { Overlay };
