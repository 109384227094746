import React, { useState } from "react";
import { TabButton } from '../utility/tabButton.jsx';
import { ShirtivalConfig } from "./productionPlaning/config.jsx";
import { ShirtivalShiftPlan } from "./productionPlaning/shiftPlan.jsx";
import { ShirtivalMachineEdit } from "./productionPlaning/machineEdit.jsx";
import { ShirtivalProductionPlan } from "./productionPlaning/productionPlan.jsx";

const ShirtivalProductionPlaning = ({ currentUser }) => {

    const [activeComponent, setActiveComponent] = useState('planing');

    const renderComponent = (component) => {
        switch (component) {
            case 'planing':
                return <ShirtivalProductionPlan currentUser={currentUser} />;
            case 'shiftplan':
                return <ShirtivalShiftPlan currentUser={currentUser} />;
            case 'machines':
                return <ShirtivalMachineEdit currentUser={currentUser} />;
            case 'config':
                return <ShirtivalConfig currentUser={currentUser} />;
            default:
                return null;
        }
    };

    return (
        <div className="w3-container">
            <h2>Produktionsplanung</h2>
            <div className="w3-section">
                <div className="w3-bar w3-theme">
                    <TabButton onClick={() => setActiveComponent('planing')} isActive={activeComponent === 'planing'}>Planung</TabButton>
                    <TabButton onClick={() => setActiveComponent('shiftplan')} isActive={activeComponent === 'shiftplan'}>Schichtplan</TabButton>
                    <TabButton onClick={() => setActiveComponent('machines')} isActive={activeComponent === 'machines'}>Maschinenverwaltung</TabButton>
                    <TabButton onClick={() => setActiveComponent('config')} isActive={activeComponent === 'config'}>Konfiguration</TabButton>
                </div>
            </div>
            {renderComponent(activeComponent)}
        </div>
    );
};

export { ShirtivalProductionPlaning };