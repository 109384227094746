import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API } from '../../../services/apiUrl';
import { Modal } from '../../utility/modal';

const UserDelete = ( {currentUser} ) => {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [message, setMessage] = useState("");
    const [deleteUserId, setDeleteUserId] = useState(null);

    useEffect(() => {
        setLoading(true);

        axios.post(`${API}/admin/user/queryUsers.php`, {
            currentUser,
        })
            .then(response => {
                if (Array.isArray(response.data)) {
                    setUsers(response.data);
                    setLoading(false);
                }
            })
            .catch(error => {
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.error) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                setMessage(resMessage);
            });
    }, []);

    const confirmDeleteUser = (userId) => {
        setDeleteUserId(userId);
        setMessage(`Ask-Bist du sicher dass du den Benutzer mit der ID ${userId} löschen willst?`);
    };

    const deleteUser = (userId) => {
        setLoading(true);
        axios.post(`${API}/admin/user/deleteUser.php`, {
            userId: deleteUserId,
            currentUser
        })
            .then(response => {
                setUsers(users.filter(user => user.user_id !== deleteUserId));
                setLoading(false);
                setMessage('Suc-'+response.data);
            })
            .catch(error => {
                console.log(error)
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.error) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                setMessage(resMessage);
            });
    };

    return (
        <>
            {message && (
                <Modal
                    message={message}
                    setMessage={setMessage}
                    onConfirm={deleteUser} // Pass deleteUser as onConfirm
                />
            )}

            {loading ? (
                <div className="w3-section">
                    <p>Loading...</p>
                </div>
                
            ) : (
                <>
                <div className="w3-section w3-border">
                {users.map((user, index) => (
                    <div className={`w3-bar ${index !== users.length - 1 ? 'w3-border-bottom' : ''} w3-light-grey`} key={user.user_id}>
                        <span className="w3-bar-item">{user.email}</span>
                        <span className="w3-bar-item w3-button w3-hover-text-theme w3-right" onClick={() => confirmDeleteUser(user.user_id)}>
                            <i className="fa fa-user-times w3-large"></i>
                        </span>
                    </div>
                    )
                )}
                </div>
                </>
            )}

        </>
    );
};

export { UserDelete };


