import { Modal } from "../utility/modal";
import { API } from "../../services/apiUrl";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import AuthService from "../../services/auth.service";
import { useNavigate } from "react-router-dom";

const UserEdit = ({ currentUser }) => {
    
    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(false);
    const [message, setMessage] = useState("");
    const [changedStates, setChangedStates] = useState(false);
    const [errors, setErrors] = useState({});

    const [user, setUser] = useState({});
    const [userOriginal, setUserOriginal] = useState({});

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        setLoadingInitial(true);
        axios.post(`${API}user/userInformation.php`, {
            methode: 'GET',
            currentUser
        })
        .then(response => {
            response.data.api_key = currentUser.api_key;
            response.data.password = currentUser.password;
            setUserOriginal(JSON.parse(JSON.stringify(response.data)))
            setUser(response.data)
            setLoadingInitial(false)
        })
        .catch(error => {
            const resMessage =
                (error.response &&
                error.response.data &&
                error.response.data.detail) ||
                error.message ||
                error.toString();
            const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
            const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
            setMessage(errorMessage);
            setLoadingInitial(false)
        })
    }, []);

    const formatDateString = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const dateStringToTimestamp = (dateString) => {
      const [year, month, day] = dateString.split('-').map(Number);
      const dateObject = new Date(year, month - 1, day);
      return dateObject.getTime();
    };

    const resetChanges = () => {
        setLoading(true)
        setErrors({});
        setUser(userOriginal);
        setLoading(false)
    };

    const togglePasswordVisibility = (e, fieldName) => {
        if (fieldName === 'password') {
            e.stopPropagation();
            setShowPassword(prevState => !prevState);
        }
    };

    useEffect(() => {
        const areUsersEqual = JSON.stringify(user) === JSON.stringify(userOriginal);
        if (areUsersEqual) {
            setChangedStates(false);
        } else {
            setChangedStates(true);
        }
    }, [user]);

    const saveChanges = () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setLoading(true);
            axios.post(`${API}user/userInformation.php`, {
                methode: 'PUT',
                user,
                currentUser
            })
            .then(response => {
                const passwordOld = JSON.parse(JSON.stringify(user.password))
                const apiKeyOld = JSON.parse(JSON.stringify(user.api_key))
                const passwordOriginalOld = JSON.parse(JSON.stringify(userOriginal.password))
                const apiKeyOriginalOld = JSON.parse(JSON.stringify(userOriginal.api_key))
                setUserOriginal(JSON.parse(JSON.stringify(response.data)))
                setUser(response.data)
                setChangedStates(false);
                setMessage('Suc-Änderungen erfolgreich gespeichert');
                setLoading(false);
                if (passwordOld !== passwordOriginalOld || apiKeyOld !== apiKeyOriginalOld) {
                    AuthService.logout();
                    navigate('/');
                }
            })
            .catch(error => {
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.detail) ||
                    error.message ||
                    error.toString();
                const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
                const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
                setMessage(errorMessage);
                setLoading(false);
            });
        }
    };

    const handleInputChange = (e, keyName, type) => {
        const { value } = e.target;
        const newValue = type === 'date' ? dateStringToTimestamp(value) : value;
        setUser({ ...user, [keyName]: newValue });
        setErrors({ ...errors, [keyName]: null });
    };

    const validateFields = () => {
        const newErrors = {};
        const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
        const apiKeyRegex = /^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/i;
        const telephoneNumberRegex = /^(\+?\d{1,3}|\d{1,4})?(?:\d ?){6,14}\d$/;
    
        if (!user.firstName || user.firstName.trim() === '') {
            newErrors.firstName = 'Vorname darf nicht leer sein';
        }
        if (!user.lastName || user.lastName.trim() === '') {
            newErrors.lastName = 'Nachname darf nicht leer sein';
        }
        if (!user.email || !/\S+@\S+\.\S+/.test(user.email)) {
            newErrors.email = 'E-Mail ist ungültig';
        }
        if (user.password && !strongPasswordRegex.test(user.password)) {
            newErrors.password = 'Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten';
        }
        if (user.api_key && !apiKeyRegex.test(user.api_key)) {
            newErrors.api_key = 'Ungültiges API-Key-Format';
        }
        if (user.mobilePhoneNumber && !telephoneNumberRegex.test(user.mobilePhoneNumber)) {
            newErrors.mobilePhoneNumber = 'Ungültige Handynummer. Das Format sollte entweder mit 0 oder mit dem Ländercode beginnen';
        }
        if (user.phoneNumber && !telephoneNumberRegex.test(user.phoneNumber)) {
            newErrors.phoneNumber = 'Ungültige Telefonnummer. Das Format sollte entweder mit 0 oder mit dem Ländercode beginnen';
        }
        if (user.faxNumber && !telephoneNumberRegex.test(user.faxNumber)) {
            newErrors.faxNumber = 'Ungültige Faxnummer. Das Format sollte entweder mit 0 oder mit dem Ländercode beginnen';
        }
        return newErrors;
    };

    const fields = [
        { key: 'firstName', type: 'text', placeholder: 'Vorname', icon: 'fa-user' },
        { key: 'lastName', type: 'text', placeholder: 'Nachname', icon: 'fa-user' },
        { key: 'email', type: 'email', placeholder: 'E-Mail', icon: 'fa-envelope', readOnly: true },
        { key: 'mobilePhoneNumber', type: 'tel', placeholder: 'Handy-Nummer', icon: 'fa-mobile' },
        { key: 'phoneNumber', type: 'tel', placeholder: 'Telefon-Nummer', icon: 'fa-phone' },
        { key: 'faxNumber', type: 'tel', placeholder: 'Fax-Nummer', icon: 'fa-fax' },
        { key: 'birthDate', type: 'date', placeholder: 'Geburtstag',icon: 'fa-birthday-cake' },
        { key: 'createdDate', type: 'date', placeholder: 'Erstelldatum',icon: 'fa-calendar-plus-o', readOnly: true },
        { key: 'api_key', type: 'text', placeholder: 'API-Key', icon: 'fa-key' },
        { key: 'password', type: 'password', placeholder: 'Passwort', icon: 'fa-user-secret' },
    ];

    return (
        <React.Fragment>
            {message && (
            <Modal message={message} setMessage={setMessage}/>
            )}
            {loadingInitial ? (    
                <div className="w3-section w3-display-container" style={{height: '200px'}}>
                    <div className="w3-display-middle">
                        <i className='fa fa-spinner fa-spin w3-margin-right w3-large'></i>
                    </div>    
                </div> 
            ) : (
                <React.Fragment>
                {changedStates && (
                    <div className="w3-section w3-mobile w3-bar">
                        <button 
                            className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`} 
                            style={{
                                pointerEvents: loading ? 'none' : 'auto'
                            }}
                            onClick={saveChanges}
                        >
                            {!loading ? (
                                <>
                                    <i className='fa fa-save w3-margin-right w3-small'></i>
                                </>
                            ) : (
                                <>
                                    <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                                </>
                            )}
                            Speichern
                        </button>
                        <button 
                            className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right`} 
                            style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                marginRight: '8px'
                            }}
                            onClick={resetChanges}
                        >
                            {!loading ? (
                                <>
                                    <i className='fa fa-save w3-margin-right w3-small'></i>
                                </>
                            ) : (
                                <>
                                    <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                                </>
                            )}
                            Zurücksetzen
                        </button>
                    </div>
                )}    
                {user && (
                    <form key={user.id} className="w3-section">
                    {fields.map((field, index) => (
                        <div key={index} className={`w3-row-padding w3-half w3-bar`} style={{ marginBottom: "16px", padding: '0 0' }}>
                            <div className="w3-bar-item w3-margin-right" style={{ width: "40px" }}>
                                <i className={`w3-xlarge w3-text-theme fa ${field.icon}`}></i>
                            </div>
                            <div className="w3-rest">
                                <input
                                    className={`w3-input w3-border ${field.readOnly ? 'w3-light-grey' : ''}`}
                                    type={field.key === 'password' ? (showPassword ? 'text' : 'password') : field.type}
                                    value={field.type === 'date' ? formatDateString(new Date(user[field.key])) : user[field.key]}
                                    readOnly={field.readOnly || false}
                                    onChange={(e) => handleInputChange(e, field.key, field.type)}
                                    style={{
                                        cursor: field.readOnly ? 'not-allowed' : ''
                                    }}
                                />
                                <div 
                                    className="w3-small w3-white w3-round w3-border"
                                    style={{
                                        position: 'absolute',
                                        padding: '0px 3px',
                                        margin: '0px 7px',
                                        transform: 'translateY(-10px)',
                                    }}> 
                                    {field.placeholder}
                                </div>
                                {field.key == 'password' && (
                                <div 
                                    onClick={(e) => togglePasswordVisibility(e, field.key)}
                                    style={{
                                        position: 'absolute',
                                        right: '30px',
                                        transform: 'translateY(-32px)',
                                    }}
                                >
                                    {showPassword ? (
                                        <i className="fa fa-eye-slash"></i>
                                    ) : (
                                        <i className="fa fa-eye"></i>
                                    )}
                                </div>
                                )}
                                {errors[field.key] && (
                                    <div 
                                        className="w3-small w3-red w3-panel"
                                        style={{
                                            padding: '0 10px',
                                            marginBottom: '0px'
                                        }}> 
                                        {errors[field.key]}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                    </form>
                )}
            </React.Fragment>
            )}
        </React.Fragment>
    );     
};

export { UserEdit };