import React, { useState, useEffect, useRef } from 'react';
import { TabButton } from '../utility/tabButton.jsx';
import { UserEdit } from './userEdit.jsx'; 

const User = ({currentUser}) => {

  const [activeComponent, setActiveComponent] = useState('userEdit');

  const renderComponent = (component) => {
    switch (component) {
        case 'userEdit':
            return <UserEdit currentUser={currentUser} />;
        // case 'picEdit':
        //     return <BlanketPurchaseOrder currentUser={currentUser} />;
        default:
            return '';
    }
  };

  return (
      <div className="w3-container">
          <h2>Benutzerverwaltung</h2>
          <div className="w3-section">
              <div className="w3-bar w3-theme">
                  <TabButton onClick={() => setActiveComponent('userEdit')} isActive={activeComponent === 'userEdit'}>Benutzer bearbeiten</TabButton>
                  <TabButton onClick={() => setActiveComponent('picEdit')} isActive={activeComponent === 'picEdit'}>Bild bearbeiten</TabButton>
              </div>
          </div>
          {renderComponent(activeComponent)}
      </div>
  );
};

export { User };