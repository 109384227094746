import React, { useState } from "react";
import { TabButton } from '../utility/tabButton.jsx';
import { NavigationCreate } from './navigation/create.jsx';
import { NavigationDelete } from "./navigation/delete.jsx";
import { NavigationChange } from "./navigation/company.jsx";

const AdminNavigation = ({ currentUser }) => {

    const [activeComponent, setActiveComponent] = useState(null);

    const renderComponent = (component) => {
        switch (component) {
            case 'create':
                return <NavigationCreate />;
            case 'companyRole':
                return <NavigationChange currentUser={currentUser} />;
            case 'delete':
                // return <NavigationDelete currentUser={currentUser} />;
                return null;
            default:
                return null;
        }
    };

    return (
        <>
            <div className="w3-container">
                <h2>Navigationsverwaltung</h2>
            </div>
            <div className="w3-container">
                <div className="w3-bar w3-theme">
                    {currentUser.company_id == 1 && (
                        <>
                        <TabButton onClick={() => setActiveComponent('create')} isActive={activeComponent === 'create'}>Erstellen</TabButton>
                        </>
                    )}
                    <TabButton onClick={() => setActiveComponent('companyRole')} isActive={activeComponent === 'companyRole'}>Firmen- / Rollenzuordnung</TabButton>
                    <TabButton onClick={() => setActiveComponent('delete')} isActive={activeComponent === 'delete'}>Löschen</TabButton>
                </div>
            </div>
            <div className="w3-container">
                {renderComponent(activeComponent)}
            </div>
        </>
    );
};

export { AdminNavigation };