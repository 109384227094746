import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API } from '../../../services/apiUrl';
import useCursor from '../../utility/useCursor';
import { Modal } from '../../utility/modal';

const BlanketPurchaseOrder = ({currentUser, frame}) => {

    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(false);
    const [tables, setTables] = useState({ [frame]: [] });
    const [initialTables, setInitialTables] = useState({ [frame]: [] });
    const [editableRows, setEditableRows] = useState([]);
    const [changedStates, setChangedStates] = useState({});
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [restmenge, setRestmenge] = useState([]);
    const [durchschnitt, setDurchschnitt] = useState([]);
    const [bezugsquelle, setBezugsquelle] = useState([]);
    const [loadingCrosshair, setLoadingCrosshair] = useState(false);
    const [message, setMessage] = useState("");
    const [errorRows, setErrorRows] = useState([]);

    useEffect(() => {
        setLoadingInitial(true);
        axios.post(`${API}mkg/blanketPurchaseOrderList.php`, {
            methode: 'GET',
            active: true
        })
        .then(response => {
            const formattedData = formatData(response.data);
            setTables(prevTables => ({
                ...prevTables,
                ...formattedData,
            }));
            setInitialTables(JSON.parse(JSON.stringify(formattedData)));
            setLoadingInitial(false)
        })
        .catch(error => {
            const resMessage =
                (error.response &&
                error.response.data &&
                error.response.data.detail) ||
                error.message ||
                error.toString();
            const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
            const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
            setMessage(errorMessage);
            setLoadingInitial(false)
        })
    }, []);

    const formatData = (data) => {
        const formattedData = {};
        data.forEach(item => {
            const type = item.type;
            delete item.type;
            if (!formattedData[type]) {
                formattedData[type] = [];
            }
            formattedData[type].push(item);
        });
        return formattedData;
    };

    useCursor(loadingCrosshair);

    useEffect(() => {      
        setTables(prevTables => ({
            ...prevTables,
            [frame]: prevTables[frame] || [],
        })); 
        setChangedStates(prevStates => ({
          ...prevStates,
          [frame]: prevStates[frame] || false
        }));
        setSupplierOptions(prevOptions => ({
            ...prevOptions,
            [frame]: prevOptions[frame] || [],
        }));
        setRestmenge(prevOptions => ({
            ...prevOptions,
            [frame]: prevOptions[frame] || 0,
        }));
        setDurchschnitt(prevOptions => ({
            ...prevOptions,
            [frame]: prevOptions[frame] || 0,
        }));
        setBezugsquelle({
            [frame]: {
                date: bezugsquelle[frame]?.date || '',
                value: bezugsquelle[frame]?.value || 0
            }
        });
    }, [frame]);

    const handleAddRow = () => {
        const newRow = {
            id: '',
            supplierId: '',
            supplier: '',
            quantity: 0,
            startDate: '',
            endDate: '',
            price: 0,
            remainingQuantity: 0,
            note: '',
            active: 1
        };
        setTables(prevTables => ({
            ...prevTables,
            [frame]: [...prevTables[frame], newRow],
        }));
        setEditableRows(prevIndices => ({
            ...prevIndices,
            [frame]: [...(prevIndices[frame] || []), tables[frame].length]
        }));
        setChangedStates(prevStates => ({
            ...prevStates,
            [frame]: true
        }));
        setSupplierOptions(prevOptions => ({
            ...prevOptions,
            [frame]: [...prevOptions[frame], []],
        }));
    };

    const handleInputChange = (e, rowIndex, fieldName) => {
        const { value, checked } = e.target;
        if (fieldName == 'supplier') {
            axios.post(`${API}mkg/blanketPurchaseOrderOptions.php`, {
                currentUser,
                supplier: value
            })
            .then(response => {
                setSupplierOptions(prevOptions => {
                    const newOptions = { ...prevOptions };
                    newOptions[frame][rowIndex] = response.data;
                    return newOptions;
                })
            })
            .catch(error => {
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.detail) ||
                    error.message ||
                    error.toString();
                const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
                const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
                setMessage(errorMessage);
            })
            setTables(prevTables => {
                const updatedRows = [...prevTables[frame]];
                updatedRows[rowIndex][fieldName] = value;
                const supplierOption = supplierOptions[frame][rowIndex]?.find(option => option.company === value);
                if (supplierOption) {
                    updatedRows[rowIndex]['supplierId'] = supplierOption.id;
                }
                return {
                    ...prevTables,
                    [frame]: updatedRows,
                };
            });      
        } else if (fieldName == 'quantity') {
            setTables(prevTables => {
                const updatedRows = [...prevTables[frame]];
                updatedRows[rowIndex][fieldName] = value;
                updatedRows[rowIndex]['remainingQuantity'] = value;
                return {
                    ...prevTables,
                    [frame]: updatedRows,
                };
            });
        } else if (fieldName == 'active') {
            const activeValue = checked ? 1 : 0;
            setTables(prevTables => {
                const updatedRows = [...prevTables[frame]];
                updatedRows[rowIndex][fieldName] = activeValue;
                return {
                    ...prevTables,
                    [frame]: updatedRows,
                };
            });
        } else {
            setTables(prevTables => {
                const updatedRows = [...prevTables[frame]];
                updatedRows[rowIndex][fieldName] = value;
                return {
                    ...prevTables,
                    [frame]: updatedRows,
                };
            });
        }
        setChangedStates(prevStates => ({
            ...prevStates,
            [frame]: true,
        }));
    };

    useEffect(() => {
        setLoadingCrosshair(true);
        const sumRemainingQuantities = {};
        const totalCosts = {};
        Object.keys(tables).forEach(type => {
            sumRemainingQuantities[type] = 0;
            let totalCost = 0;
            tables[type].forEach(row => {
                if (row.active === 1) {
                    if (row.remainingQuantity !== null && row.remainingQuantity !== 0) {
                        sumRemainingQuantities[type] += parseFloat(row.remainingQuantity);
                    } else if (row.quantity !== null && row.quantity !== 0) {
                        sumRemainingQuantities[type] += parseFloat(row.quantity);
                    }
                    const cost = parseFloat(row.price) * (row.remainingQuantity !== null && row.remainingQuantity !== 0 ? parseFloat(row.remainingQuantity) : parseFloat(row.quantity));
                    totalCost += cost;
                }
            });
            const averageCost = sumRemainingQuantities[type] !== 0 ? totalCost / sumRemainingQuantities[type] : 0;
            totalCosts[type] = averageCost;
        });
        setRestmenge(sumRemainingQuantities);
        setDurchschnitt(totalCosts);
        setLoadingCrosshair(false);
    }, [tables]);

    const saveChanges = () => {
        const rowsWithError = [];
        for (const type in tables) {
            for (const item of tables[type]) {
                if (item.hasOwnProperty('id') && item['id'] === '') {
                    const valuesToCheck = Object.keys(item).filter(key => key !== 'note' && key !== 'remainingQuantity' && key !== 'id');
                    const hasEmptyOrZeroValue = valuesToCheck.some(key => item[key] === '' || item[key] === 0 || item[key] === "0");
                    if (hasEmptyOrZeroValue) {
                        rowsWithError.push(item);
                    }
                }
            }
        }
        if (rowsWithError.length > 0) {
            setErrorRows(prevState => [...prevState, ...rowsWithError]);
            return;
        }
        setLoading(true);
        axios.post(`${API}mkg/blanketPurchaseOrderList.php`, {
            methode: 'UPDATE',
            data: tables,
            currentUser
        })
        .then(response => {
                const filteredData = response.data.filter(item => item.active === 1);
                setTables(formatData(filteredData));
                setChangedStates({});
                setErrorRows([])
                setEditableRows([])
                setMessage('Suc-Die neuen Rahmenverträge wurden erfolgreich gespeichert.')
                setLoading(false)
        })
        .catch(error => {
            const resMessage =
                (error.response &&
                error.response.data &&
                error.response.data.detail) ||
                error.message ||
                error.toString();
            const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
            const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
            setMessage(errorMessage);
            setLoading(false)
        })
    };

    const handlePriceChange = () => {
        setLoading(true);
        setLoadingCrosshair(true);
        axios.post(`${API}mkg/articleSupplySource.php`, {
            price: bezugsquelle,
            currentUser
        })
        .then(response => {
            setMessage('Suc-Die Bezugsquellen wurden erfolgreich angepasst.')
            setLoading(false)
            setLoadingCrosshair(false);
        })
        .catch(error => {
            console.log(error)
            const resMessage =
                (error.response &&
                error.response.data &&
                error.response.data.detail) ||
                error.message ||
                error.toString();
            const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
            const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
            setMessage(errorMessage);
            setLoading(false)
            setLoadingCrosshair(false);
        })
    }

    const handleInputChangeBezugsquelle = (e, fieldName) => {
        const { value } = e.target;
        setBezugsquelle(prevBezugsquelle => ({
            [frame]: {
                ...prevBezugsquelle[frame],
                [fieldName]: value
            }
        }));
    }

    useEffect(() => {
        setBezugsquelle(prevBezugsquelle => ({
            ...prevBezugsquelle,
            [frame]: {
                ...prevBezugsquelle[frame],
                value: durchschnitt[frame]
            }
        }));
    }, [durchschnitt]);

    const resetChanges = () => {
        setLoading(true)
        setTables(JSON.parse(JSON.stringify(initialTables)));
        setChangedStates({});
        setErrorRows([])
        setEditableRows([])
        setLoading(false)
    };

    const removeRowFromErrors = (rowToRemove) => {
        setErrorRows(prevErrorRows => prevErrorRows.filter(row => row !== rowToRemove));
    };

    return (
        <>
        {message && (
        <Modal message={message} setMessage={setMessage}/>
        )}
        {loadingInitial ? (    
            <div className="w3-section w3-display-container" style={{height: '200px'}}>
                <div className="w3-display-middle">
                    <i className='fa fa-spinner fa-spin w3-margin-right w3-large'></i>
                </div>    
            </div> 
        ) : (
            <>
            <div className="w3-section w3-mobile w3-bar">
                <button className='w3-button w3-theme w3-bar-item' onClick={handleAddRow}>
                    <i className='fa fa-plus w3-margin-right w3-small'></i>Neuen Rahmenvertag
                </button>
                {changedStates[frame] && (
                    <React.Fragment>
                    <button 
                        className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`} 
                        style={{
                            pointerEvents: loading ? 'none' : 'auto'
                        }}
                        onClick={saveChanges}
                    >
                        {!loading ? (
                            <>
                                <i className='fa fa-save w3-margin-right w3-small'></i>
                            </>
                        ) : (
                            <>
                                <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                            </>
                        )}
                        Speichern
                    </button>
                    <button 
                        className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`} 
                        style={{
                            pointerEvents: loading ? 'none' : 'auto',
                            marginRight: '8px'
                        }}
                        onClick={resetChanges}
                    >
                        {!loading ? (
                            <>
                                <i className='fa fa-save w3-margin-right w3-small'></i>
                            </>
                        ) : (
                            <>
                                <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                            </>
                        )}
                        Zurücksetzen
                    </button>
                    </React.Fragment>
                )}     
            </div>
            <div className="w3-section w3-mobile">
                <table className="w3-table-all w3-bordered w3-border" style={{tableLayout: 'auto'}}>
                    <thead>
                        <tr key={'header'.frame} className='w3-light-grey'>
                            <th style={{width: 'auto'}}>Nummer</th>
                            <th style={{width: 'auto'}}>Lieferant</th>
                            <th style={{width: 'auto'}}>Ursprungsmenge</th>
                            <th style={{width: 'auto'}}>Laufzeit von</th>
                            <th style={{width: 'auto'}}>Laufzeit bis</th>
                            <th style={{width: 'auto'}}>Preis</th>
                            <th style={{width: 'auto'}}>Restmenge</th>
                            <th style={{width: 'auto'}}>Bemerkung</th>
                            <th style={{width: 'auto'}}>Aktiv</th>
                        </tr>
                    </thead>
                    <tbody>
                    {tables[frame]?.map((row, index) => (
                        <React.Fragment key={`${frame}-${index}`}>
                        <tr key={index} className='w3-white'>
                            <td style={{padding: '0 0'}}>
                                <input
                                    className='w3-input w3-border-0 w3-white'
                                    type="number"
                                    value={row.id}
                                    onChange={(e) => handleInputChange(e, index, 'id')}
                                    disabled={true}
                                />
                            </td>
                            <td style={{padding: '0 0'}}>
                                <input
                                    title={row.supplier}
                                    className='w3-input w3-border-0 w3-white'
                                    type="text"
                                    value={row.supplier}
                                    onChange={(e) => handleInputChange(e, index, 'supplier')}
                                    disabled={!editableRows[frame]?.includes(index)}
                                    list={`supplierOptions.${frame}.${index}`}
                                />
                                {editableRows[frame]?.includes(index) && (
                                    <datalist id={`supplierOptions.${frame}.${index}`}>
                                    {supplierOptions[frame][index]?.map((option, optionIndex) => (
                                        <option key={`supplierOption.${frame}.${index}.${optionIndex}`} value={option.company}>{option.supplierNumber}</option>
                                    ))}
                                </datalist>)}
                            </td>
                            <td style={{padding: '0 0'}}>
                                <input
                                    className='w3-input w3-border-0 w3-white'
                                    style={{textAlign: 'right'}}
                                    type="number"
                                    value={row.quantity}
                                    min={0}
                                    step={1}
                                    onChange={(e) => handleInputChange(e, index, 'quantity')}
                                    disabled={!editableRows[frame]?.includes(index)}
                                />
                            </td>
                            <td style={{padding: '0 0'}}>
                                <input
                                    className='w3-input w3-border-0 w3-white'
                                    style={{textAlign: 'right'}}
                                    type="date"
                                    value={row.startDate}
                                    onChange={(e) => handleInputChange(e, index, 'startDate')}
                                    disabled={!editableRows[frame]?.includes(index)}
                                />
                            </td>
                            <td style={{padding: '0 0'}}>
                                <input
                                    className='w3-input w3-border-0 w3-white'
                                    style={{textAlign: 'right'}}
                                    type="date"
                                    value={row.endDate}
                                    onChange={(e) => handleInputChange(e, index, 'endDate')}
                                    disabled={!editableRows[frame]?.includes(index)}
                                />
                            </td>
                            <td style={{padding: '0 0'}}>
                                <input
                                    className='w3-input w3-border-0 w3-white'
                                    style={{textAlign: 'right'}}
                                    type="number"
                                    step={.01}
                                    min={0}
                                    value={row.price}
                                    onChange={(e) => handleInputChange(e, index, 'price')}
                                    disabled={!editableRows[frame]?.includes(index)}
                                />
                            </td>
                            <td style={{padding: '0 0'}}>
                                <input
                                    className='w3-input w3-border-0 w3-white'
                                    style={{textAlign: 'right'}}
                                    type="number"
                                    value={row.remainingQuantity}
                                    onChange={(e) => handleInputChange(e, index, 'remainingQuantity')}
                                    disabled={editableRows[frame]?.includes(index)}
                                    min={0}
                                    step={1}
                                />
                            </td>
                            <td style={{padding: '0 0'}}>
                                <textarea
                                    className='w3-input w3-border-0 w3-white'
                                    style={{textAlign: 'right', resize: 'none'}}
                                    type="textarea"
                                    value={row.note}
                                    onChange={(e) => handleInputChange(e, index, 'note')}
                                    disabled={!editableRows[frame]?.includes(index)}
                                    rows="2"
                                    cols="20"
                                />
                            </td>
                            <td style={{padding: '0 0'}} className='w3-display-container'>
                                <input
                                    className='w3-input w3-border-0 w3-white w3-display-middle'
                                    type="checkbox"
                                    checked={row.active}
                                    onChange={(e) => handleInputChange(e, index, 'active')}
                                    disabled={editableRows[frame]?.includes(index)}
                                />
                            </td>
                        </tr>
                        {errorRows.includes(row) && 
                        <tr>
                            <td colSpan={9} className="w3-display-container w3-red">
                                <div >
                                <span 
                                    onClick={() => removeRowFromErrors(row)}
                                    className="w3-button w3-display-topright">
                                        X
                                </span>
                                <p>Fülle bitte alle Felder (außer der Bemerkung) aus, damit gespeichert werden kann.</p>
                                </div>
                            </td>
                        </tr>
                        }
                        </React.Fragment>
                    ))}  
                    </tbody>
                    <tfoot>
                        <tr className='w3-light-grey'>
                            <th colSpan={6}></th>
                            <th className='w3-right-align'>
                            {restmenge[frame] !== null && restmenge[frame] !== undefined && restmenge[frame] !== NaN
                            ? restmenge[frame].toLocaleString('de-DE', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })
                            : '0'}
                            </th>
                            <th colSpan={2}></th>
                        </tr>
                        <tr className='w3-light-grey'>
                            <td colSpan={6}></td>
                            <td className='w3-right-align'>
                            {durchschnitt[frame] !== null && durchschnitt[frame] !== undefined && durchschnitt[frame] !== NaN
                            ? durchschnitt[frame].toLocaleString('de-DE', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })
                            : '0'} €
                            </td>
                            <td colSpan={2} className='w3-right-align'>Durchschnittswert</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className="w3-section w3-mobile">
                <div key={frame+'.bezugsquelle'} className='w3-bar'>
                    <div className="w3-bar-item">
                        Startdatum:
                    </div>
                    <input 
                        className='w3-input w3-bar-item'
                        placeholder='Startdatum'
                        type='date'
                        value={bezugsquelle[frame]?.date}
                        onChange={(e) => handleInputChangeBezugsquelle(e, 'date')}
                    />
                    <div className="w3-bar-item">
                        Durchschnitt:
                    </div>
                    <input 
                        className='w3-input w3-bar-item'
                        placeholder='Durchschnitt'
                        type='number'
                        min={0}
                        step={0.01}
                        value={bezugsquelle[frame]?.value}
                        onChange={(e) => handleInputChangeBezugsquelle(e, 'value')}
                    />
                    <button 
                        style={{
                            pointerEvents: loading ? 'none' : 'auto'
                        }}
                        className={`w3-button w3-bar-item ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right`} 
                        onClick={handlePriceChange}>
                        {!loading ? (
                            <>
                                <i className='fa fa-plus w3-margin-right w3-small'></i>Bezugsquelle anpassen
                            </>
                        ) : (
                            <>
                                <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>Bezugsquelle anpassen
                            </>
                        )}
                    </button>
                </div>
            </div>
            </>
        )}
        </>
    );
};

export { BlanketPurchaseOrder };