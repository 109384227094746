import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import { API } from '../../../services/apiUrl';
import { Modal } from '../../utility/modal';
import AuthService from '../../../services/auth.service';
import CheckButton from "react-validation/build/button";

const required = (value) => {
    if (!value) {
      return (
        <div className="w3-container w3-margin-bottom w3-round w3-border w3-pale-red w3-border-red alert ">
              This field is required!
        </div>
      );
    }
};

const UserCreate = ( {currentUser} ) => {
    const form = useRef();
    const checkBtn = useRef();

    const [emailOptions, setEmailOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [company, setCompany] = useState('');
    const [role, setRole] = useState('');
    const [apikey, setApikey] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };
    
    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const onChangeCompany = (e) => {
        const company = e.target.value;
        setCompany(company);
    };

    const onChangeRole = (e) => {
        const role = e.target.value;
        setRole(role);
    };

    const onChangeApiKey = (e) => {
        const apikey = e.target.value;
        setApikey(apikey);
    };

    const createUser = (e) => {
        e.preventDefault();
    
        setMessage("");
        setLoading(true);
    
        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            axios.post(`${API}/admin/user/createUser.php`, {
                email,password,company,role,apikey
            })
            .then(response => {
                setLoading(false);
                setMessage('Suc-'+response.data);
                setEmail('');
                setPassword('');
            })
            .catch(error => {
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.error) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                setMessage(resMessage);
            })
        } else {
          setLoading(false);
        }
      };

    useEffect(() => {
        if (email.trim() !== '') {
            axios.post(`${API}/admin/user/queryEmail.php`, {
                currentUser,
                email
            })
                .then(response => {
                    if (Array.isArray(response.data) && response.data.length > 0) {
                        setEmailOptions(response.data);
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data) {
                        try {
                            const responseData = JSON.parse(error.response.data);
                            const { status, title } = responseData;
                            setMessage(`Fehler beim Abrufen der E-Mail-Optionen: ${status} - ${title}`);
                        } catch (parseError) {
                            console.error('Fehler beim Parsen der Antwortdaten:', parseError);
                        }
                    } else {
                        setMessage('Fehler beim Abrufen der E-Mail-Optionen:', error);
                    }
                });
        }
    }, [email]);

    useEffect(() => {
            axios.post(`${API}/admin/user/queryCompany.php`, {
                currentUser
            })
                .then(response => {
                    if (Array.isArray(response.data) && response.data.length > 0) {
                        setCompanyOptions(response.data);
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data) {
                        try {
                            const responseData = JSON.parse(error.response.data);
                            const { status, title } = responseData;
                            setMessage(`Fehler beim Abrufen der E-Mail-Optionen: ${status} - ${title}`);
                        } catch (parseError) {
                            console.error('Fehler beim Parsen der Antwortdaten:', parseError);
                        }
                    } else {
                        setMessage('Fehler beim Abrufen der E-Mail-Optionen:', error);
                    }
                });

    }, []);

    useEffect(() => {
        axios.get(`${API}/admin/user/queryRoles.php`)
            .then(response => {
                if (Array.isArray(response.data) && response.data.length > 0) {
                    setRoleOptions(response.data);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    try {
                        const responseData = JSON.parse(error.response.data);
                        const { status, title } = responseData;
                        setMessage(`Fehler beim Abrufen der E-Mail-Optionen: ${status} - ${title}`);
                    } catch (parseError) {
                        console.error('Fehler beim Parsen der Antwortdaten:', parseError);
                    }
                } else {
                    setMessage('Fehler beim Abrufen der E-Mail-Optionen:', error);
                }
            });

    }, []);

    return (
        <>
        {message && (
        <Modal message={message} setMessage={setMessage}/>
        )}

        {loading ? (
                <div className="w3-section">
                    <p>Loading...</p>
                </div>
                
            ) : (
                <>
                <Form className="w3-section" onSubmit={ createUser } ref={form}>
                    <label htmlFor="email">E-Mail:</label>
                    <Input 
                        className="w3-input w3-margin-bottom" 
                        type="email" 
                        name="email" 
                        list="email" 
                        value={email} 
                        validations={[required]}
                        onChange={onChangeEmail}
                    />
                    <label htmlFor="password">Passwort:</label>
                    <Input
                        className="w3-input w3-margin-bottom"
                        type="password" 
                        name="password"
                        value={password} 
                        validations={[required]}
                        onChange={onChangePassword}
                    />
                    <label htmlFor="company">Unternehmen:</label>
                    <Select
                        className="w3-input w3-margin-bottom"
                        type="name"
                        name="company"
                        validations={[required]}
                        onChange={onChangeCompany}
                        value={company}
                    >
                    <option value="" disabled>Treffe eine Auswahl</option>
                    {companyOptions.map(option => (
                        <option key={option.id} value={option.id} >{option.name}</option>
                    ))}
                    </Select>
                    <label htmlFor="userrole">Benutzerrolle:</label>
                    <Select
                        className="w3-input w3-margin-bottom"
                        type="name"
                        name="userrole"
                        validations={[required]}
                        onChange={onChangeRole}
                        value={role}
                    >
                    <option value="" disabled>Treffe eine Auswahl</option>
                    {roleOptions.map(option => (
                        <option key={option.role_id} value={option.role_id} >{option.role_name}</option>
                    ))}
                    </Select>
                    <label htmlFor="apikey">Api-Key:</label>
                    <Input
                        className="w3-input w3-margin-bottom"
                        type="name" 
                        name="apikey"
                        value={apikey} 
                        onChange={onChangeApiKey}
                    />
                    <button className="w3-button w3-theme" type="submit" disabled={loading}>
                        {!loading && (
                            <i className="fa fa-save fa-fw w3-margin-right"></i>
                        )}
                        {loading && (
                            <i className="fa fa-spinner fa-spin fa-fw w3-margin-right"></i>
                        )}
                        <span>Erstellen</span>
                    </button>
                    <datalist id="email">
                        {emailOptions.map(option => (
                            <option key={option} value={option} />
                        ))}
                    </datalist>
                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form>
                </>
            )}
        </>
    );
};

export { UserCreate };


