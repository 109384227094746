import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Logo } from "./utility/Logo";
import axios from "axios";
import { API } from "../services/apiUrl";

import AuthService from "../services/auth.service";
import { Modal } from "./utility/modal";

const required = (value) => {
  if (!value) {
    return (
      <div className="w3-container w3-margin-bottom w3-round w3-border w3-pale-red w3-border-red alert ">
            This field is required!
      </div>
    );
  }
};

const Login = () => {
  const form = useRef();
  const checkBtn = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cookie, setCookie] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showSettings, setShowSettings] = useState(false);

  const toggleSettings = () => {
      setShowSettings(prevState => !prevState);
  };

  const pwReset = () => {
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    if (!isValidEmail(email)) {
      setMessage('Gib eine korrekte E-Mail-Adresse an.')
    } else {
      setMessage(`Ask-Möchtest du das Passwort zu der E-Mail "${email}" wirklich zurücksetzen?`)
    }
  };

  const onConfirm = () => {
    axios.post(`${API}auth/reset_password.php`, {
      generate: true,
      email
    })
    .then(response => {
      setMessage(`Suc-Es wurde eine E-Mail an "${email}" versendet.`)
    })
    .catch(error => {
      console.log(error);
        const resMessage =
            (error.response &&
            error.response.data &&
            error.response.data.detail) ||
            error.message ||
            error.toString();
        const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
        const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
        setMessage(errorMessage);
    })
  };

  const navigate = useNavigate();

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeCookie= (e) => {
    const cookie = e.target.checked;
    setCookie(cookie);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.login(email, password).then(
        () => {
          navigate("/");
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.error) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="w3-display-container" style={{ height: '100vh', width: '100vw' }}>

        {message && (
        <Modal message={message} setMessage={setMessage} onConfirm={onConfirm}/>
        )}

        <div className="w3-container w3-display-middle w3-mobile" style={{ width: '30rem' }}>
            <div className="w3-card-4 w3-light-grey w3-padding">
                <div className="w3-center">
                    <Logo src='/SGB-logo.png' alt="Logo" width="50%" className="w3-margin-top"/>
                </div>
                
                <Form onSubmit={handleLogin} ref={form} className="w3-container">
                <div className="w3-section">
                    <label htmlFor="email">E-Mail</label>
                    <Input
                    type="email"
                    className="w3-input w3-margin-bottom w3-light-grey"
                    name="email"
                    value={email}
                    onChange={onChangeEmail}
                    validations={[required]}
                    />

                    <label htmlFor="password">Password</label>
                    <Input
                    type="password"
                    className="w3-input w3-margin-bottom w3-light-grey"
                    name="password"
                    value={password}
                    onChange={onChangePassword}
                    validations={[required]}
                    />

                    <div className="w3-bar w3-section">
                        <button className="w3-button w3-bar-item w3-theme w3-padding" disabled={loading}>
                            {!loading && (
                                <i className="fa fa-sign-in fa-fw w3-margin-right"></i>
                            )}
                            {loading && (
                                <i className="fa fa-spinner fa-spin fa-fw w3-margin-right"></i>
                            )}
                            <span>Login</span>
                        </button>
                        <div className="w3-button w3-bar-item w3-theme w3-padding w3-right" onClick={toggleSettings}>
                            <i className="fa fa fa-shield fa-fw w3-margin-right"></i>Einstellungen
                        </div>
                    </div>
                    {showSettings && (
                    <div className="w3-section w3-animate-opacity" id="wizard">
                        <div className="w3-border-bottom">
                            <h4 className="">Einstellungen</h4>
                        </div>
                        <div className="w3-border-bottom">
                            <input className="w3-check w3-margin-bottom w3-light-grey w3-margin-right" type="checkbox" name="cookie" id="cookie" checked={cookie} onChange={onChangeCookie}/>
                            <label>Ich möchte in Zukunft angemeldet bleiben.</label>
                        </div>
                        <div style={{padding: '8px 0px'}}>
                            <a href="#" onClick={pwReset}>Ich möchte mein Passwort zurücksetzen.</a>
                        </div>
                    </div>)}
                </div>

                <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form>
            </div>
        </div>
    </div>
  );
};

export default Login;