import React, { useState } from 'react';

const Calculation = ({table, frame}) => {

    const [pfostentyp, setPfostentyp] = useState('C80');
    const [pfostenlength, setPfostenlength] = useState(100);
    const [gewichtmeter, setGewichtmeter] = useState(0.1);
    const uniqueSuppliers = [...new Set(table[frame].map(item => ({ name: item.supplier, id: item.supplierId })))];

    const findPriceForSupplier = (supplierId, type, length, table) => {
        const supplierItem = table.galvanizing.find(item => item.supplierId === supplierId && item.type === type && length >= item.lengthfrom && length <= item.lengthto);
        return supplierItem ? supplierItem.price : null;
    };

    return (
        <div className="w3-section w3-mobile">
            <table className="w3-table-all w3-bordered w3-border" style={{tableLayout: 'auto'}}>
                <thead>
                    <tr className='w3-light-grey'>
                        <th>Pfostentyp</th>
                        <th>Pfostenlänge</th>
                        <th>Gewicht pro Meter</th>
                        <th>Gewicht pro Pfosten (kg)</th>
                        {uniqueSuppliers.map((supplier, index) => (
                            <th key={index} title={supplier.name}>{supplier.name.split(' ')[0]}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{padding: '0 0'}}>
                            <select
                                className='w3-input w3-border-0 w3-white'
                                style={{textAlign: 'left'}}
                                value={pfostentyp}
                                onChange={(e) => setPfostentyp(e.target.value)}
                            >
                                <option value="C80">C80</option>
                                <option value="C90">C90</option>
                                <option value="C100">C100</option>
                                <option value="C120">C120</option>
                            </select>
                        </td>
                        <td style={{padding: '0 0'}}>
                            <input
                                className='w3-input w3-border-0 w3-white'
                                style={{textAlign: 'right'}}
                                type="number"
                                step={100}
                                min={100}
                                max={8600}
                                value={pfostenlength}
                                onChange={(e) => setPfostenlength(e.target.value)}
                            />
                        </td>
                        <td style={{padding: '0 0'}}>
                            <input
                                className='w3-input w3-border-0 w3-white'
                                style={{textAlign: 'right'}}
                                type="number"
                                step={.1}
                                min={0}
                                value={gewichtmeter}
                                onChange={(e) => setGewichtmeter(e.target.value)}
                            />    
                        </td>
                        <td style={{padding: '0 0'}}>
                            <input
                                className="w3-input w3-border-0 w3-white"
                                style={{ textAlign: 'right' }}
                                type="number"
                                value={(pfostenlength / 1000 * gewichtmeter).toFixed(2)}
                                disabled={true}
                            />
                        </td>
                        {uniqueSuppliers.map((supplier, index) => {
                            const price = findPriceForSupplier(supplier.id, pfostentyp, pfostenlength, table);
                            const uniquePrices = uniqueSuppliers
                                .map(supplier => findPriceForSupplier(supplier.id, pfostentyp, pfostenlength, table))
                                .map(price => parseFloat(price))
                                .filter(price => !isNaN(price));
                            const lowestPrice = Math.min(...uniquePrices);
                            const isLowestPrice = parseFloat(price) === lowestPrice;

                            return (
                                <td key={index} style={{padding: '0 0'}}>
                                    <input
                                        className={`w3-input w3-border-0 ${isLowestPrice ? 'w3-green' : 'w3-white'}`}
                                        style={{ textAlign: 'right' }}
                                        type="number"
                                        value={price ? ((price / 1000) * (pfostenlength / 1000 * gewichtmeter)).toFixed(2) : 0}
                                        disabled={true}
                                    />
                                </td>
                            );
                        })}
                    </tr>
                </tbody>
            </table>
        </div>
    )}

export { Calculation };