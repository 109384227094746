import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import validator from 'validator';
import { API } from '../../../services/apiUrl';
import { Modal } from '../../utility/modal';
import AuthService from '../../../services/auth.service';
import CheckButton from "react-validation/build/button";

const required = (value) => {
    if (!value) {
      return (
        <div className="w3-container w3-margin-bottom w3-round w3-border w3-pale-red w3-border-red alert ">
              Das Feld muss befüllt sein!
        </div>
      );
    }
};

const NavigationCreate = () => {
    const form = useRef();
    const checkBtn = useRef();

    const [label, setLabel] = useState('');
    const [category, setCategory] = useState('');
    const [iconClass, setIconClass] = useState('');
    const [link, setLink] = useState('');
    const [iconClassOptions, setIconClassOptions] = useState([]);
    const [iconClassAll, setIconClassAllOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [isSubCategory, setIsSubCategory] = useState(true);
    const [isTopCategory, setIsTopCategory] = useState(false);
    const [isTopCategoryWithSub, setIsTopCategoryWithSub] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState([]);

    const onChangeLabel = (e) => {
        const label = e.target.value;
        setLabel(label);
    };
    
    const onChangeCategory = (e) => {
        const category = e.target.value;
        setCategory(category);
    };

    const handleCheckboxChange = (e) => {
        setIsSubCategory(prevState => !prevState);
        setIsTopCategory(prevState => !prevState);
        setIsTopCategoryWithSub(false);
        if (!e.target.checked) {
            setCategory(''); // Reset category when unchecking subcategory
            setLink(''); // Reset link when checking topcategory
        }
    };
    
    const handleCheckboxChangeTwo = (e) => {
        setIsSubCategory(prevState => !prevState);
        setIsTopCategory(prevState => !prevState);
        if (!e.target.checked) {
            setLink(''); // Reset link when checking topcategory
            setCategory(''); // Reset category when unchecking subcategory
        }
    };

    const handleCheckboxChangeThree = (e) => {
        setIsTopCategoryWithSub(prevState => !prevState);
        if (!e.target.checked) {
            setLink(''); // Reset link when checking topcategory
        }
    };

    const onChangeIconClass = (e) => {
        const iconClass = e.target.value;
        setIconClass(iconClass);
    };

    const onChangeLink = (e) => {
        const link = e.target.value;
        setLink(link);
    };

    useEffect(() => {
        setLoading(true);
        axios.post('https://api.fontawesome.com', {
            query: `
                query {
                    release(version: "6.x") {
                        icons {
                            id
                            label
                            shim {
                                id
                                prefix
                            }
                        }
                    }
                }
            `
        })
        .then(response => {
            const iconData = response.data.data.release.icons;
            const icons = iconData.filter(icon => icon.shim !== null).map(icon => `fa fa-${icon.shim.id}`);
            setIconClassAllOptions(icons);
            setLoading(false);
        })
        .catch(error => {
            let errorMessage = 'An error occurred while fetching Font Awesome icons';
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error.message;
            } else if (error.message) {
                errorMessage = error.message;
            }
            setLoading(false);
            setMessage(errorMessage);
        })
    }, []);

    useEffect(() => {
        if (iconClass.trim() !== '' && iconClass.length >= 3) {
            const filteredIcons = iconClassAll.filter(icon => icon.toLowerCase().includes(iconClass.toLowerCase()));
            setIconClassOptions(filteredIcons);
        }
    }, [iconClass]);

    useEffect(() => {
        setLoading(true);

        axios.get(`${API}/admin/navigation/queryCategory.php`)
            .then(response => {
                if (Array.isArray(response.data)) {
                    setCategory('');
                    setCategoryOptions(response.data);
                    setLoading(false);
                }
            })
            .catch(error => {
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.error) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                setMessage(resMessage);
            });
    }, []);

    const createNavItem = (e) => {
        e.preventDefault();
    
        setMessage("");
        setLoading(true);
    
        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            console.log(label)
            console.log(iconClass)
            console.log(category)
            console.log(link)
            axios.post(`${API}admin/navigation/createNavigation.php`, {
                label,iconClass,category,link
            })
            .then(response => {
                setLoading(false);
                setMessage('Suc-'+response.data);
                setLabel('');
                setCategory('');
                setIconClass('');
                setIsSubCategory(true);
                setIsTopCategory(false);
            })
            .catch(error => {
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.error) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                setMessage(resMessage);
            })
        } else {
          setLoading(false);
        }
      };

    return (
        <>
        {message && (
        <Modal message={message} setMessage={setMessage}/>
        )}

        {loading ? (
                <div className="w3-section">
                    <p>Loading...</p>
                </div>    
            ) : (
                <>
                <Form className="w3-section" onSubmit={ createNavItem } ref={form}>
                    <label htmlFor="labelName">Label:</label>
                    <Input 
                        className="w3-input w3-margin-bottom" 
                        type="name" 
                        name="labelName"  
                        value={label} 
                        validations={[required]}
                        onChange={onChangeLabel}
                    />
                    <label htmlFor="iconClass">Icon:</label>
                    <Input 
                        className="w3-input w3-margin-bottom" 
                        type="name" 
                        name="iconClass"
                        list="iconClass"   
                        value={iconClass} 
                        validations={[required]}
                        onChange={onChangeIconClass}
                    />
                    <datalist id="iconClass">
                        {iconClassOptions.map(option => (
                            <option key={option} value={option} />
                        ))}
                    </datalist>
                    <div className="w3-margin-bottom">
                    <input 
                        className="w3-check" 
                        type="checkbox" 
                        id="isSubCategory" 
                        name="isSubCategory"
                        checked={isSubCategory}
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="isSubCategory">Handelt es sich um eine Unterpunkt?</label>
                    </div>
                    <div className="w3-margin-bottom">
                    <input 
                        className="w3-check" 
                        type="checkbox" 
                        id="isTopCategory" 
                        name="isTopCategory"
                        checked={isTopCategory}
                        onChange={handleCheckboxChangeTwo}
                    />
                    <label htmlFor="isTopCategory">Handelt es sich um einen Menü-Oberpunkt?</label>
                    </div>
                    {isTopCategory && (
                    <>
                    <div className="w3-margin-bottom">
                    <input 
                        className="w3-check" 
                        type="checkbox" 
                        id="isTopCategoryWithSub" 
                        name="isTopCategoryWithSub"
                        checked={isTopCategoryWithSub}
                        onChange={handleCheckboxChangeThree}
                    />
                    <label htmlFor="isTopCategoryWithSub">Handelt es sich um einen Menü-Oberpunkt mit Unterpunkten?</label>
                    </div>
                    </>
                    )}
                    {isSubCategory && (
                    <>
                    <div className="w3-margin-bottom">
                    <label htmlFor="category">Kategorie:</label>
                    <Select
                        className="w3-input"
                        type="text"
                        name="category"
                        onChange={onChangeCategory}
                        value={category}
                        key={isTopCategory ? 'categoryTopCategoryInput' : 'categorySubCategoryInput'}
                        required
                    >
                    <option key={-1} value='' disabled>Wähle eine Kategorie</option>
                    {categoryOptions.map(option => (
                        <option key={option.id} value={option.label} >{option.label}</option>
                    ))}
                    </Select>
                    </div>
                    </>
                    )}
                    {(isSubCategory || (!isSubCategory && isTopCategoryWithSub)) && (
                    <>
                    <div className="w3-margin-bottom">
                    <label htmlFor="link">Link</label>
                    <Input 
                        className="w3-input" 
                        type="text" 
                        id="link" 
                        name="link"
                        value={link}
                        onChange={onChangeLink}
                        validations={[required]}
                        key={isSubCategory ? 'isSubCategoryInput' : 'isTopCategoryInput'}
                    />     
                    </div>
                    </> 
                    )}  
                    <button className="w3-button w3-theme" type="submit" disabled={loading}>
                        {!loading && (
                            <i className="fa fa-save fa-fw w3-margin-right"></i>
                        )}
                        {loading && (
                            <i className="fa fa-spinner fa-spin fa-fw w3-margin-right"></i>
                        )}
                        <span>Erstellen</span>
                    </button>
                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form>
                </>
            )}
        </>
    );
};

export { NavigationCreate };