import { useEffect } from 'react';

const useCursor = (loadingCrosshair) => {
    useEffect(() => {
        if (loadingCrosshair) {
            document.body.style.cursor = 'progress'; // Change cursor to progress
        } else {
            document.body.style.cursor = 'default'; // Reset cursor to default
        }
        return () => {
            document.body.style.cursor = 'default'; // Reset cursor on component unmount
        };
    }, [loadingCrosshair]);
};

export default useCursor;